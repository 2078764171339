import React from 'react'
// Data
import { Link as LocalizedLink } from 'gatsby-plugin-react-i18next'
// Assets
import { ReactComponent as LogoImage } from '../../../assets/images/logos/logo.svg'
import { ReactComponent as LogoImageFr } from '../../../assets/images/logos/logo-fr.svg'
import { useI18n } from '../../../lib/hooks'

const Logo = () => {
  const link = '/'
  const { language } = useI18n()

  return (
    <div className="logo">
      <LocalizedLink to={link} aria-label="Always Together logo">
        {language !== 'ca-fr' ? (
          <LogoImage />
        ) : (
          <LogoImageFr />
        )}
      </LocalizedLink>
    </div>
  )
}

export default Logo
