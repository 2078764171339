import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { ResourceCard, Text } from '../../cards'
import Grid from '../Grid/Grid'
// Assets
import './_ResourcesList.scss'

const propTypes = {
  data: PropTypes.shape({
    anchor: PropTypes.string,
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    resourceItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      }),
      file: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
        format: PropTypes.string,
        size: PropTypes.string
      })
    }))
  }),
}

const defaultProps = {
  data: {
    anchor: '',
    topText: {
      title: '',
      text: ''
    },
    resourceItems: {
      title: '',
      text: '',
      image: {
        alt: '',
        file: {}
      },
      file: {
        label: '',
        url: '',
        format: '',
        size: ''
      }
    }
  },
}

const ResourcesList = ({ data, ...props }) => {
  const { resourceItems, topText, anchor } = defaultsDeep(data, defaultProps.data)

  const renderResource = (teaser, index) => (
    <ResourceCard key={uniqueId(`resources-list__item-${index}`)} data={teaser} />
  )

  return (
    <section className="resources-list" id={anchor || uniqueId('resources-list-')}>
      <div className="resources-list__inner">
        {topText.title && (
          <div className="testimonials-list__top-text">
            <Text data={topText} />
          </div>
        )}
        {resourceItems && resourceItems.length && (
          <Grid
            data={{
              gridItems: resourceItems.map(renderResource),
              itemsPerColumn: 2,
              needsContainer: false,
            }}
          />
        )}
      </div>
    </section>
  )
}

// Export Props
ResourcesList.defaultProps = defaultProps
ResourcesList.propTypes = propTypes
// Export Component
export default ResourcesList
