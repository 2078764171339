import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, values } from 'lodash'
// Assets
import './_Text.scss'

const THEME = {
  default: 'default',
  page: 'page'
}

const propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.oneOf(values(THEME)),
    title: PropTypes.string,
    text: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    theme: THEME.default,
    title: '',
    text: ''
  },
}

const Text = ({ data, ...props }) => {
  const { theme, title, text } = defaultsDeep(data, defaultProps.data)

  return (
    <div
      className="text"
      data-theme={theme}
    >
      <div className="text__inner">
        <div className="text__content">
          {theme === THEME.page ? (
            <h1 className="text__title" dangerouslySetInnerHTML={{ __html: title }} />
          ) : (
            <h2 className="text__title" dangerouslySetInnerHTML={{ __html: title }} />
          )}
          <div
						className="text__body"
						dangerouslySetInnerHTML={{ __html: text }}
					/>
        </div>
      </div>
    </div>
  )
}

// Export Props
Text.defaultProps = defaultProps
Text.propTypes = propTypes
// Export Component
export default Text