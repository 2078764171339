import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, values } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_Hero.scss'

const THEME = {
  default: 'default',
  leftPosition: 'left-position'
}

const propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.oneOf(values(THEME)),
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    })
  }),
}

const defaultProps = {
  data: {
    theme: THEME.default,
    image: {
      alt: '',
      file: {}
    }
  },
}

const Hero = ({ data, ...props }) => {
  const { image, theme } = defaultsDeep(data, defaultProps.data)
  const heroImage = getImage(image.file)
  return (
    <div className="hero" data-theme={theme}>
      <GatsbyImage image={heroImage} alt={image.alt} />
    </div>
  )
}

// Export Props
Hero.defaultProps = defaultProps
Hero.propTypes = propTypes
// Export Enums
Hero.THEME = THEME
// Export Component
export default Hero