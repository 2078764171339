import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useScroll } from '../../../lib/hooks'
// Assets
import './_VideoHero.scss'
import { ReactComponent as PlayIcon } from '../../../assets/images/icons/video-button.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg'

const propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    }),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    video: PropTypes.string,
  }),
}

const defaultProps = {
  data: {
    image: {
      alt: '',
      file: {}
    },
    title: '',
    subtitle: '',
    video: ''
  },
}

const VideoHero = ({ data, ...props }) => {
  const { image, title, subtitle, video } = defaultsDeep(data, defaultProps.data)
  const elementRef = useRef(null)
  const [openModal, setOpenModal] = useState(false)
  const [stuckModal, setStuckModal] = useState(false)
  const [videoBottom, setVideoBottom] = useState(1000)
  const heroImage = getImage(image.file)
  const { scrollY } = useScroll()

  const openVideoModal = () => {
    setOpenModal(true)
  }

  const closeVideoModal = () => {
    setOpenModal(false)
  }

  useLayoutEffect(() => {
    const { current } = elementRef
    if (current) {
      const videoHeight = current.offsetHeight
      setVideoBottom(videoHeight + current.offsetTop)
    }
  },[openModal])

  useLayoutEffect(() => {
    if (openModal) {
      if (scrollY >= videoBottom && stuckModal !== true) {
        setStuckModal(true)
      } else if (scrollY < videoBottom && stuckModal !== false) {
        setStuckModal(false)
      }
    }
  }, [scrollY, openModal])

  const modalContent = useMemo(() => {
    if (openModal) {
      return (
        <div
          className={`video-hero__modal ${stuckModal ? 'video-hero__modal--stuck' : ''}`}
          ref={elementRef}
        >
          <div className="video-hero__modal-inner">
            <div
              className="video-hero__modal-close"
              role="button"
              tabIndex="0"
              onClick={closeVideoModal}
              onKeyPress={closeVideoModal}
              aria-label="Close Video Modal"
            >
              <CloseIcon />
            </div>
            <div
              className="video-hero__modal-content"
              dangerouslySetInnerHTML={{ __html: video }}
            />
          </div>
        </div>)
    }
    return false
  }, [openModal, stuckModal])

  return (
    <div className="video-hero">
      <div className="video-hero__inner">
        <div className="video-hero__outer">
          <h1 className="video-hero__title">{title}</h1>
          <div className="video-hero__subtitle">
            <div
              className="modal__open"
              role="button"
              tabIndex="0"
              onClick={openVideoModal}
              onKeyPress={openVideoModal}
            >
              <div className="modal__open-label">
                {subtitle}
              </div>
              <div className="modal__open-icon">
                <PlayIcon />
              </div>
            </div>
          </div>
          { modalContent }
        </div>
        <div
          className="video-hero__image"
        >
          <GatsbyImage image={heroImage} alt={image.alt} />
        </div>
      </div>
    </div>
  )
}

// Export Props
VideoHero.defaultProps = defaultProps
VideoHero.propTypes = propTypes
// Export Component
export default VideoHero