import React, { useLayoutEffect, useRef, useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { get, defaultsDeep, uniqueId } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { SvgIcon } from '@material-ui/core'
import { useI18n, useWindowSize } from '../../../lib/hooks'
// Components.
import { Logo, SiteLink } from '../../nav'
import { HeaderMenu, LanguageSwitcher } from '../../containers'
// Assets.
import './_Header.scss'
import { ReactComponent as FacebookIcon } from '../../../assets/images/social-icons/facebook-square.svg'
import { ReactComponent as LinkedinIcon } from '../../../assets/images/social-icons/linkedin-in.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/images/social-icons/twitter.svg'
import { ReactComponent as YoutubeIcon } from '../../../assets/images/social-icons/youtube.svg'
import { ReactComponent as TrudellLogo } from '../../../assets/images/logos/trudell-logo.svg'
import { ReactComponent as TrudellLogoUk } from '../../../assets/images/logos/trudell-logo-uk.svg'

const HeaderRendered = ({ data, ...props }) => {
  const windowSize = useWindowSize(300)
  const { width: windowWidth } = windowSize
  const { t, language } = useI18n()
  const ref = useRef()
  const { socialLinks, trudellLink } = defaultsDeep(data, props)
  const [menuOpen, setMenuOpen] = useState(false)
  const [ariaHiddenMenuToggle, setAriaHiddenMenuToggle] = useState(true)
  const [ariaHiddenMenu, setAriaHiddenMenu] = useState(true)
  const [ariaHiddenTopLinks, setAriaHiddenTopLinks] = useState(true)

  useLayoutEffect(() => {
    setAriaHiddenMenuToggle((windowWidth >= 960))
    setAriaHiddenTopLinks((windowWidth >= 576))
    if (!menuOpen || windowWidth >= 960) {
      document.body.classList.remove('no-scroll')
    } else if (menuOpen && windowWidth < 960) {
      document.body.classList.add('no-scroll')
    }
  }, [windowWidth])

  useLayoutEffect(() => {
    setAriaHiddenMenu(menuOpen || windowWidth >= 960)
    if (!menuOpen || windowWidth >= 960 ) {
      document.body.classList.remove('no-scroll')
    }
  }, [windowWidth, menuOpen])

  const handleMenuToggle = (anchorLink = false) => {
    if (anchorLink === true) {
      setMenuOpen(false)
    } else {
      setMenuOpen(!menuOpen)
    }
    if (windowWidth < 960) {
      document.body.classList.toggle('no-scroll')
    }
  }

  const renderIcon = (label) => {
    let icon
    switch (label){
      case 'facebook':
        icon = <FacebookIcon />
        break
      case 'linkedin':
        icon = <LinkedinIcon />
        break
      case 'twitter':
        icon = <TwitterIcon />
        break
      default:
        icon = <YoutubeIcon />
    }
    return (
      <SvgIcon>
        {icon}
      </SvgIcon>
    )
  }

  const socialLinksRender = socialLinks.map((item, index) => {
    return (
      <SiteLink
        key={uniqueId(`header__social-item-${index}_`)}
        className="header__social-item"
        aria-label={item.label}
        href={item.link}
      >
        {renderIcon(item.label)}
      </SiteLink>
    )
  })

  return (
    <>
      <SiteLink className="header__skip-link" href="#main">
        {t('skipLabel')}
      </SiteLink>
      <header ref={ref} className={`header ${menuOpen ? 'header--menu-opened' : ''}`}>
        <div
          className="header__top"
          aria-hidden={!ariaHiddenTopLinks}
        >
          <div className="header__inner">
            {trudellLink !== null && (
              <SiteLink className="header__trudell-logo" href={trudellLink} aria-label="Trudell Medical logo">
                {language === 'uk' ? (
                  <TrudellLogoUk />
                ) : (
                  <TrudellLogo />
                )}
              </SiteLink>
            )}
            <div
              className="header__right-side"
            >
              {socialLinksRender && (
                <div className="header__social-links">{socialLinksRender}</div>
              )}
              <LanguageSwitcher id="language-switcher-top" {...props} />
            </div>
          </div>
        </div>
        <div className="header__main">
          <div className="header__inner">
            <Logo />
            <div className="header__menu-toggle">
              <div
                className="header__menu-toggle-button"
                onClick={handleMenuToggle}
                onKeyPress={handleMenuToggle}
                role="button"
                tabIndex="0"
                aria-hidden={ariaHiddenMenuToggle}
              >
                <span className="header__menu-toggle-bar" />
                <span className="header__menu-toggle-bar" />
                <span className="header__menu-toggle-bar" />
                <span className="header__menu-toggle-bar" />
              </div>
            </div>
            <div
              className="header__menu-wrapper"
              aria-hidden={!ariaHiddenMenu}
            >
              <div className="header__menu-wrapper-inner">
                <div className="header__menu-wrapper-main">
                  <HeaderMenu toggleMenu={handleMenuToggle} {...props} />
                  {socialLinksRender && (
                    <div
                      className="header__social-links"
                      aria-hidden={ariaHiddenTopLinks}
                    >
                      {socialLinksRender}
                    </div>
                  )}
                </div>
                <div
                  className="header__menu-wrapper-bottom"
                  aria-hidden={ariaHiddenTopLinks}
                >
                  {trudellLink !== null && (
                    <SiteLink className="header__trudell-logo" href={trudellLink} aria-label="Trudell Medical logo">
                      <TrudellLogo />
                    </SiteLink>
                  )}
                  <LanguageSwitcher id="language-switcher-id" {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

HeaderRendered.propTypes = {
  data: PropTypes.shape({}).isRequired
}

export default function Header(props) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderLayout {
          layout: layoutJson {
            content: header {
              socialLinks {
                label
                link
              }
              trudellLink
            }
          }
        }
      `}
      render={(data) => {
        const headerData = get(data, 'layout.content', [])
        return <HeaderRendered data={headerData} {...props} />
      }}
    />
  )
}
