import React, { useLayoutEffect, useState } from 'react'
import { defaultsDeep } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import Tabs from 'react-responsive-tabs'
// Components
import TabContent from './TabContent'
// Assets
// IMPORTANT you need to include the default styles
import 'react-responsive-tabs/styles.css'
import './_SpacerTabs.scss'

const propTypes = {
  data: PropTypes.shape({
    tabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired
}

const defaultProps = {}

const SpacerTabs = ({ data, ...props }) => {
  const { tabs } = defaultsDeep(data, defaultProps.data)
  const [selectedTab, setSelectedTab] = useState(0)

  useLayoutEffect(() => {
    setSelectedTab(0)
  }, [tabs])

  const tabItems = tabs.map((tab, index) => {
    const tabContent = <TabContent data={tab} />
    return ({
      title: tab.label,
      getContent: () => tabContent,
      key: index,
      tabClassName: 'spacer-tabs__tab-label',
      panelClassName: 'spacer-tabs__content',
    })
  })

  return (
    <div className="spacer-tabs" role="tablist" aria-label="Spacer Tabs">
      <Tabs
        items={tabItems}
        transformWidth={703}
        tabsWrapperClass="spacer-tabs__labels"
        showMore={false}
        selectedTabKey={selectedTab}
      />
    </div>
  )
}

// Export Props
SpacerTabs.defaultProps = defaultProps
SpacerTabs.propTypes = propTypes
// Export Component
export default SpacerTabs