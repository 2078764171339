import React, { forwardRef, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId, values } from 'lodash'
// Data
import {
  InputLabel,
  FilledInput,
  Select as MuiSelect,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { useI18n } from '../../../lib/hooks'
import { Forms } from '../../../schema/types'
// Assets
import './_Select.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOf(values(Forms.SelectStyles).concat([undefined, ''])),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      name: PropTypes.string,
      checked: PropTypes.bool,
    })
  )
}

const defaultProps = {
  className: '',
  style: '',
  label: '',
  onChange: null,
  value: '',
  options: []
}

// eslint-disable-next-line react/display-name
const Select = forwardRef(
  ({ className, name, onChange, ...props },
    ref) => {
    const { t } = useI18n()
    const { style, label, options, value } = defaultsDeep(props, defaultProps)
    const selectId = uniqueId(`mui-select-${style}-`)
    const commonProps = {
      ref,
      name,
      id: selectId,
    }
    const [fieldValue, setFieldValue] = useState('')
    const handleChange = (event) => {
      setFieldValue(event.target.value)
      onChange(event)
    }

    useEffect(() => {
      setFieldValue(value)
    }, [])

    const renderAttributes = () => {
      const attributes = {}
      attributes.onChange = handleChange

      switch (style) {
        default:
          attributes.multiple = false
          attributes.value = fieldValue
      }
      return attributes
    }

    const menuItems = options.map((item, index) => {
      const common = {
        key: uniqueId(`select-item-${index}_`),
        className: 'form-field__select-option',
      }
      switch (style) {
        default:
          return (
            <MenuItem {...common} value={item.value || item.label}>
              {item.label}
            </MenuItem>
          )
      }
    })

    return (
      <FormControl className={`${className} form-field__select`} data-style={style}>
        <InputLabel htmlFor={selectId}>{label}</InputLabel>
        <MuiSelect
          {...renderAttributes()}
          {...commonProps}
          input={<FilledInput />}
          MenuProps={MenuProps}
          displayEmpty
        >
          <MenuItem value="">
            <em>{ t('forms.selectEmptyOptionLabel')}</em>
          </MenuItem>
          {menuItems}
        </MuiSelect>
      </FormControl>
    )
  })

// Export Props
Select.defaultProps = defaultProps
Select.propTypes = propTypes
// Export Component
export default Select
