import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_OvercomeCard.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    })
  })
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    image: {
      alt: '',
      file: {}
    }
  }
}

const OvercomeCard = ({ data, ...props }) => {
  const { title, image } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="overcome-card">
      {title && (
        <div className="overcome-card__title" dangerouslySetInnerHTML={{ __html:title }}/>
      )}
      <div className="overcome-card__image">
        <GatsbyImage alt={image.alt} image={getImage(image.file)} />
      </div>
    </div>
  )
}

// Export Props
OvercomeCard.defaultProps = defaultProps
OvercomeCard.propTypes = propTypes
// Export Component
export default OvercomeCard
