import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { defaultsDeep } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Popover, IconButton } from '@material-ui/core'
import { useI18n, useWindowSize } from '../../../lib/hooks'
// Components
import { Button } from '../../nav'
import SpacerList from '../../containers/SpacerList/SpacerList'
// Assets
import './_Spacer.scss'
import { ReactComponent as DevelopedCanadaLogo } from '../../../assets/images/logos/developed-canada-logo.svg'
import { ReactComponent as DevelopedCanadaLogoFr } from '../../../assets/images/logos/developed-canada-logo-fr.svg'
import { ReactComponent as ChevronDropdownIcon } from '../../../assets/images/icons/chevron-dropdown.svg'
import { ReactComponent as OpenIcon } from '../../../assets/images/icons/plus.svg'
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/minus.svg'
import { ReactComponent as ReplacementLogo } from '../../../assets/images/logos/replace12-col-en.svg'
import { ReactComponent as ReplacementLogoFr } from '../../../assets/images/logos/replace12-col-fr.svg'

const propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      name: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
      size: PropTypes.string,
      price: PropTypes.string,
      buyLink: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      flowLink: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      linksText: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    additionalSpacerOptions: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  choiceSpacer: PropTypes.func
}

const defaultProps = {
  data: {
    content: {
      name: '',
      subtitle: '',
      description: '',
      size: '',
      price: '',
      buyLink: {
        title: '',
        uri: '',
      },
      flowLink: {
        title: '',
        uri: '',
      },
      linksText: '',
      images: [],
    },
    additionalSpacerOptions: []
  },
  choiceSpacer: null
}

const Spacer = ({ data, ...props }) => {
  const { t, language } = useI18n()
  const windowSize = useWindowSize(300)
  const { name, subtitle, description, size, price, buyLink, flowLink, linksText, additionalSpacerText, images } = defaultsDeep(data.content, defaultProps.data)
  const [mainImage, setMainImage] = useState(0)
  const [selectedImage, setSelectedImage] = useState(0)
  const [anchorElList, setAnchorElList] = useState([])

  const triggerMainImage = (key = 0) => {
    setMainImage(key)
    setSelectedImage(parseInt(key))
  }
  const componentRef = useRef()
  useLayoutEffect(() => {
    // Fix the issue after resize browser.
    componentRef.current.handleArrowClick()
  }, [windowSize.width])


  const handleTooltipClick = (event, index) => {
    const newAnchorElList = anchorElList.map(()=>null)
    if (!anchorElList[index]){
      newAnchorElList[index] = event.currentTarget
    }
    setAnchorElList(newAnchorElList)
  }

  const handlePopoverClose = (index) => {
    const newAnchorElList = [... anchorElList]
    newAnchorElList[index] = null
    setAnchorElList(newAnchorElList)
  }

  useEffect (()=>{
    if (!images[mainImage].tooltips){
      setAnchorElList([])
    }else{
      setAnchorElList(images[mainImage].tooltips.map(()=>null))
    }
  }, [mainImage])

  const imageList = useMemo(() => {
    return images.map((item, index) => {
      const itemImage = getImage(item.file)
      // Need to update logic for the image keys.
      const imageId = index
      return (
        <div
          key={imageId}
          className={`spacer__image-item ${selectedImage === imageId ? 'active' : ''}`}
        >
          <GatsbyImage
            alt={item.alt}
            image={itemImage}
          />
        </div>
      )
    })
  }, [images, selectedImage])

  const renderMainImage = useMemo(() => {
    return (
      <GatsbyImage
        alt={images[mainImage].alt}
        image={getImage(images[mainImage].file)}
      />
    )
  }, [images, selectedImage])

  useMemo(() => {
    setMainImage(0)
    setSelectedImage(0)
  }, [images])

  const arrow = (className) => {
    return (
      <div
        className={className}
      >
        <div className="spacer__arrow-icon">
          <ChevronDropdownIcon/>
        </div>
      </div>
    )
  }

  const arrowLeft = arrow('spacer__arrow-prev')
  const arrowRight = arrow('spacer__arrow-next')

  return (
    <div className="spacer">
      <div className="spacer__images-container">
        <div className="spacer__main-image">
          {renderMainImage}

          {images[mainImage].tooltips && images[mainImage].tooltips.map((tooltip, index) => (
            <div key={tooltip.id} className="spacer__tooltip-container" style={{ left: tooltip.left, top: tooltip.top }}>
              <IconButton
                onClick={(event)=>(handleTooltipClick(event, index))}
                aria-haspopup="true"
                aria-label="Open/Close button"
              >
                <CloseIcon style={{ display: anchorElList[index] ? 'block' : 'none' }}/>
                <OpenIcon style={{ display: anchorElList[index] ? 'none' : 'block' }}/>
              </IconButton>

              <Popover
                open={Boolean(anchorElList[index])}
                anchorEl={anchorElList[index]}
                onClose={()=>{handlePopoverClose(index)}}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: parseInt(tooltip.left.split('%')[0])<50 ? 'right' : 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: parseInt(tooltip.left.split('%')[0])<50 ? 'left' : 'right',
                }}
                disableRestoreFocus
              >
                <div className={`spacer__tooltips-inner ${parseInt(tooltip.left.split('%')[0]) < 50 ? 'spacer__tooltips-inner--right' : 'spacer__tooltips-inner--left'}`}>
                  <GatsbyImage alt={tooltip.image.alt} image={getImage(tooltip.image.file)} className='spacer__tooltips-image'/>
                  <div dangerouslySetInnerHTML={{ __html: tooltip.copy }} className='spacer__tooltips-copy'/>
                </div>
              </Popover>
            </div>
          ))}
        </div>

        <div className="spacer__image-list">
          <ScrollMenu
            ref={componentRef}
            data={imageList}
            arrowLeft={arrowLeft}
            arrowRight={arrowRight}
            selected={selectedImage}
            onSelect={triggerMainImage}
            dragging={false}
            wheel={false}
            hideSingleArrow
            scrollToSelected
            alignCenter={false}
            disableTabindex
            useButtonRole={false}
          />
        </div>
      </div>
      <div className="spacer__content">
        <h2 className="spacer__title">{name}</h2>
        <div className="spacer__subtitle">{subtitle}</div>
        <div
          className="spacer__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="spacer__logos">
          <div className="spacer__developed-logo">
            {language !== 'ca-fr' ? (
              <DevelopedCanadaLogo />
            ) : (
              <DevelopedCanadaLogoFr />
            )}
          </div>
          <div className="spacer__replacement-logo">
            {language !== 'ca-fr' ? (
              <ReplacementLogo />
            ) : (
              <ReplacementLogoFr />
            )}
          </div>
        </div>
        <div className="spacer__bottom-content">
          <div className="spacer__size">{t('spacer.sizeLabel')} {size}</div>
          {language === 'en' && (
            <div className="spacer__price">
              {t('spacer.priceLabel')} <span>{price}</span> {t('spacer.currency')}
            </div>
          )}
          <div className="spacer__links">
            {buyLink && (
              <Button
                className="spacer__buy-link"
                theme={Button.THEME.default}
                link={buyLink}
              />
            )}
            {flowLink && (
              <Button
                className="spacer__flow-link"
                theme={Button.THEME.blue}
                link={flowLink}
              />
            )}
            {linksText && (
              <div className="spacer__links-text">{linksText}</div>
            )}
          </div>
        </div>
      </div>
      {data.additionalSpacerOptions && data.additionalSpacerOptions.length > 0 && (
        <div className="spacer__additional-options">
          <SpacerList
            spacers={data.additionalSpacerOptions}
            choiceSpacer={props.choiceSpacer}
            additionalSpacerText={additionalSpacerText}
            theme={SpacerList.THEME.additionalOptions}
            {...props}
          />
        </div>
      )}
    </div>
  )
}

// Export Props
Spacer.defaultProps = defaultProps
Spacer.propTypes = propTypes

// Export Component
export default Spacer