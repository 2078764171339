import React, { forwardRef, useEffect, useState } from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
// Data
import * as PropTypes from 'prop-types'
// Assets
import './_FormField.scss'
import './_TextField.scss'

const propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.string
}

const defaultProps = {
  className: '',
  onChange: null,
  value: '',
  icon: null
}

// eslint-disable-next-line react/display-name
const TextField = forwardRef(({ className, icon, onChange, value, ...props }, ref) => {

  const [fieldValue, setFieldValue] = useState(value)
  const handleChange = (event) => {
    setFieldValue(event.target.value)
    // con.log('change', event, ref)
    onChange(event)
  }
  useEffect(() => {
    setFieldValue(value)
  }, [value])

  return (
    <>
      {icon !== null && icon}
      <MuiTextField
        {...props}
        onChange={handleChange}
        value={fieldValue}
        ref={ref}
        className={`${className} form-field__text-field`}
        variant="filled"
        margin="normal"
      />
    </>
  )
})

// Exports
TextField.defaultProps = defaultProps
TextField.propTypes = propTypes
export default TextField
