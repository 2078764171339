import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { Text } from '../../cards'
// Assets
import './_TextWithListColumns.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    listItems: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string
    }))
  }),
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    listItems: {
      text: ''
    }
  },
}

const TextWithListColumns = ({ data, ...props }) => {
  const { topText, listItems } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="text-with-list-columns">
      <div className="text-with-list-columns__inner">
        {topText.title && (
          <div className="text-with-list-columns__top-text">
            <Text data={topText} />
          </div>
        )}
        {listItems && (
          <div className="text-with-list-columns__list">
            {listItems.map((item, index)=> (
              <div
                key={uniqueId(`itext-with-list-columns__list-item-${index}`)}
                className="text-with-list-columns__list-item"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

// Export Props
TextWithListColumns.defaultProps = defaultProps
TextWithListColumns.propTypes = propTypes
// Export Component
export default TextWithListColumns
