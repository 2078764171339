import React from 'react'
import { defaultsDeep } from 'lodash'
// Data
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'gatsby'
import { useI18n } from '../../../lib/hooks'
import { translateUrl } from '../../../lib'
// Assets.
import './_LanguageSwitcher.scss'
import { ReactComponent as UKFlag } from '../../../assets/images/icons/UK.svg'
import { ReactComponent as CAFlag } from '../../../assets/images/icons/CA.svg'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    className: 'language-switcher__popover',
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const LanguageSwitcher = (props) => {
  const { location: { pathname: currentPath = '/' } = {}, id: selectId } = defaultsDeep(props)
  const { language, languages } = useI18n()
  const selectedFlag = language !== 'uk' ? 'ca' : 'uk'
  const replacedPath = currentPath.replace(`/${language}/`, '/')

  return (
    <div className="language-switcher">
      {languages.includes('uk') ? (
        <Select
					className="language-switcher__select"
					value={selectedFlag}
					disableUnderline
					inputProps={{ name: 'language' }}
					MenuProps={MenuProps}
					id={selectId}
				>
          <MenuItem value="ca">
            <Link
							className="language-switcher__menu-link"
							to={translateUrl(replacedPath, language, 'en')}
						>
              <div className="language-switcher__flag-icon">
                <CAFlag />
              </div>
              <div className="language-switcher__label">CA</div>
            </Link>
          </MenuItem>
          <MenuItem value="uk">
            <Link
							className="language-switcher__menu-link"
							to={translateUrl(replacedPath, language, 'uk')}
						>
              <div className="language-switcher__flag-icon">
                <UKFlag />
              </div>
              <div className="language-switcher__label">UK</div>
            </Link>
          </MenuItem>
        </Select>
      ) : (
        <div className="language-switcher__country">
          <div className="language-switcher__flag-icon">
            <CAFlag />
          </div>
          <div className="language-switcher__label">CA</div>
        </div>
      )}
      {languages.includes('ca-fr') && (
        <div className="language-switcher__links">
          {language !== 'uk' ? (
            <>
              <Link to={translateUrl(replacedPath, language, 'en')}>English</Link>
              <Link to={translateUrl(replacedPath, language, 'ca-fr')}>Français</Link>
            </>
          ) : (
            <Link to={translateUrl(replacedPath, language, 'uk')}>English</Link>
          )}
        </div>
      )}
    </div>
  )
}

export default LanguageSwitcher
