import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { OvercomeCard, Text } from '../../cards'
import Grid from '../Grid/Grid'

import { Button } from '../../nav'

// Assets
import './_OvercomeList.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    overcomeItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      })
    }))
  }),
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    overcomeItems: {
      title: '',
      text: '',
      image: {
        alt: '',
        file: {}
      }
    }
  },
}

const OvercomeList = ({ data, ...props }) => {

  const { link, topText, overcomeItems, anchor } = defaultsDeep(data, defaultProps.data)

  const renderCard = (teaser, index) => (
    <OvercomeCard key={uniqueId(`overcome-list__item-${index}`)} data={teaser} />
  )

  return (
    <section className="overcome-list" id={anchor}>
      <div className="overcome-list__inner">
        {topText.title && (
          <div className="overcome-list__top-text">
            <Text data={topText}/>
          </div>
        )}

        {overcomeItems && overcomeItems.length && (
          <Grid
            data={{
              gridItems: overcomeItems.map(renderCard),
              itemsPerColumn: 2,
              needsContainer: false,
            }}
          />
        )}

        {link && (
          <div className="overcome-list__link">
            <Button
              link={link}
              theme={Button.THEME.blue}
            />
          </div>
        )}
      </div>
    </section>
  )
}

// Export Props
OvercomeList.defaultProps = defaultProps
OvercomeList.propTypes = propTypes
// Export Component
export default OvercomeList
