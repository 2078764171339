import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_TextWithIcon.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    })
  }),
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    image: {
      alt: '',
      file: {}
    }
  },
}

const TextWithIcon = ({ data, ...props }) => {
  const { title, text, image } = defaultsDeep(data, defaultProps.data)

  return (
    <section className="text-with-icon">
      <div className="text-with-icon__inner">
        <div className="text-with-icon__icon">
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        </div>
        <h2 className="text-with-icon__title">{title}</h2>
        <div
          className="text-with-icon__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  )
}

// Export Props
TextWithIcon.defaultProps = defaultProps
TextWithIcon.propTypes = propTypes
// Export Component
export default TextWithIcon
