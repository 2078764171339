import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Components
import { Button } from '../../nav'
// Assets
import './_CtaCard.scss'

const propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    file: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string
    })
  }),
}

const defaultProps = {
  data: {
    text: '',
    file: {
      label: '',
      url: ''
    }
  },
}

const CtaCard = ({ data, ...props }) => {
  const { text, file } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="cta-card">
      {text && (
        <div
          className="cta-card__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {file && file.url && (
        <div className="cta-card__link">
          <Button
            theme={Button.THEME.blue}
            link={{
              uri: file.url,
              title: file.label
            }}
          />
        </div>
      )}
    </div>
  )
}

// Export Props
CtaCard.defaultProps = defaultProps
CtaCard.propTypes = propTypes
// Export Component
export default CtaCard
