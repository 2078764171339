import React, { useContext, useLayoutEffect, useRef } from 'react'
import { defaultsDeep, values } from 'lodash'
import * as PropTypes from 'prop-types'
import ScrollMenu from 'react-horizontal-scrolling-menu'
// Data
import { SpacerChoiceContext } from '../../layout/SpacerChoice/SpacerChoiceContext'
import { useI18n, useWindowSize } from '../../../lib/hooks'
// Components
import SpacerPreview from '../../cards/SpacerPreview/SpacerPreview'
// Assets
import './_SpacerList.scss'
import { ReactComponent as ChevronDropdownIcon } from '../../../assets/images/icons/chevron-dropdown.svg'

const THEME = {
  default: 'default',
  additionalOptions: 'additional-options',
}

const propTypes = {
  spacers: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.shape({
      previewSize: PropTypes.string,
      previewImage: PropTypes.shape({}),
      selectedImage: PropTypes.shape({}),
    })
  })),
  theme: PropTypes.oneOf(values(THEME)),
  choiceSpacer: PropTypes.func,
  homePage: PropTypes.bool,
  additionalSpacerText: PropTypes.string
}

const defaultProps = {
  spacers: {
    content: {
      previewSize: '',
      previewImage: [],
      selectedImage: [],
    }
  },
  theme: THEME.default,
  choiceSpacer: null,
  homePage: false,
  additionalSpacerText: ''
}

const SpacerList = ({ spacers, theme, ...props }) => {
  const { t } = useI18n()
  const windowSize = useWindowSize(300)
  const { currentSpacer, setCurrentSpacer } = useContext(SpacerChoiceContext)
  const { homePage, additionalSpacerText } = defaultsDeep(props, defaultProps)
  const componentRef = useRef()
  const triggerSelectedSpacer = (id = '5') => {
    setCurrentSpacer(id)
  }
  useLayoutEffect(() => {
    // Fix the issue after resize browser.
    componentRef.current.handleArrowClick()
  }, [windowSize.width])

  const spacerItems = spacers.map((item, index) => {
    return (
      <SpacerPreview
        key={item.id}
        choiceSpacer={props.choiceSpacer}
        data={{
          id: item.id,
          size: item.content.previewSize,
          image: item.content.previewImage,
          hoverImage: item.content.selectedImage,
          selected: homePage ? currentSpacer : '',
          color: item.color
        }}
      />
    )
  })

  const arrow = (className) => {
    return (
      <div className={className}>
        <div className="spacer-list__arrow-icon">
          <ChevronDropdownIcon/>
        </div>
      </div>
    )
  }

  const arrowLeft = arrow('spacer-list__arrow-prev')
  const arrowRight = arrow('spacer-list__arrow-next')

  return (
    <div
      className="spacer-list"
      data-theme={theme}
    >
      {theme === THEME.additionalOptions && (
        <div className="spacer-list__label">{t('spacer.additionalOptionsLabel')}</div>
      )}
      <ScrollMenu
        ref={componentRef}
        data={spacerItems}
        arrowLeft={arrowLeft}
        arrowRight={arrowRight}
        selected={homePage ? currentSpacer : 0}
        onSelect={triggerSelectedSpacer}
        dragging={false}
        wheel={false}
        hideSingleArrow
        scrollToSelected
        alignCenter={false}
        disableTabindex
        useButtonRole={false}
      />
      {theme === THEME.additionalOptions && additionalSpacerText && (
        <div
          className="spacer-list__description"
          dangerouslySetInnerHTML={{ __html: additionalSpacerText }}
        />
      )}
      {theme === THEME.default && (
        <div className="spacer-list__bottom">
          <div>{t('spacerChoice.rainbow.start')}</div>
          <div>{t('spacerChoice.rainbow.end')}</div>
        </div>
      )}
    </div>
  )
}

// Export Props
SpacerList.defaultProps = defaultProps
SpacerList.propTypes = propTypes

// Export Component and Themes
SpacerList.THEME = THEME
export default SpacerList