import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
// Assets
import './_MdiCard.scss'

const propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    }),
    link: PropTypes.shape({
      uri: PropTypes.string,
      title: PropTypes.string
    })
  }),
  location: PropTypes.shape({})
}

const defaultProps = {
  data: {
    text: '',
    image: {
      alt: '',
      file: {}
    },
    link: {
      uri: '',
      title: ''
    }
  },
  location: {}
}

const MdiCard = ({ data, ...props }) => {
  const { location: { pathname: currentPath } } = defaultsDeep(props)
  const { text, image, link } = defaultsDeep(data, defaultProps.data)
  return (
    <div className="mdi-card">
      <AnchorLink
        to={`${currentPath}${link.uri}`}
        title={link.title}
        className="mdi-card__inner"
        stripHash
      >
        <div className="mdi-card__image">
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        </div>
        <div className="mdi-card__content">
          {text && (
          <div className="mdi-card__text" dangerouslySetInnerHTML={{ __html: text }} />
          )}
        </div>
      </AnchorLink>
    </div>
  )
}

// Export Props
MdiCard.defaultProps = defaultProps
MdiCard.propTypes = propTypes
// Export Component
export default MdiCard
