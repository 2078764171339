import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { Testimonial, Text } from '../../cards'
// Assets
import './_TestimonialsList.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    bottomText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    testimonialItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      })
    })),
  }),
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    bottomText: {
      title: '',
      text: ''
    },
    testimonialItems: {
      title: '',
      subtitle: '',
      image: {
        alt: '',
        file: {}
      }
    }
  },
}

const TestimonialsList = ({ data, ...props }) => {
  const { testimonialItems, topText, bottomText } = defaultsDeep(data, defaultProps.data)

  const renderTestimonial = (testimonial, index) => (
    <Testimonial key={uniqueId(`testimonials-list__item-${index}`)} data={testimonial} />
  )

  return (
    <section className="testimonials-list">
      <div className="testimonials-list__inner">
        {topText.title && (
          <div className="testimonials-list__top-text">
            <Text data={topText} />
          </div>
        )}
        {testimonialItems && testimonialItems.length && (
          <div className="testimonials-list__items">
            {testimonialItems.map(renderTestimonial)}
          </div>
        )}
        {bottomText.title && (
          <div className="testimonials-list__bottom-text">
            <Text data={bottomText} />
          </div>
        )}
      </div>
    </section>
  )
}

// Export Props
TestimonialsList.defaultProps = defaultProps
TestimonialsList.propTypes = propTypes
// Export Component
export default TestimonialsList
