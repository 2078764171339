import React, { useState } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
// Components
import { Footer, Header } from '../../components/layout'
import { Landing } from '../../components/page'
import { SEO } from '../../components/meta'
// Data
import { useI18n } from '../../lib/hooks'
import { getCookie, getWindow } from '../../lib'
// Assets
import './_LandingTemplate.scss'
import { ConfirmModal } from '../../components/containers'



export default function LandingTemplate({ pageContext: { data: node = {} } = {}, ...props }) {
  const win = getWindow()
  const { location } = props
  const { pathname: currentPath } = location
  const { navigate } = useI18n()
  const cookieHCP = getCookie('ViewHCP')
  const [openConfirmModal, setOpenConfirmModal] = useState(cookieHCP !== 'yes')

  const handleCancelConfirmModal = () => {
    setOpenConfirmModal(false)
    navigate('/')
  }

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
    // Save cookie to not show each time the confirmation modal.
    if (win?.document) {
      win.document.cookie = 'ViewHCP=yes'
    }
  }

  return (
    <>
      {(currentPath === '/uk/healthcare-professionals/' && openConfirmModal) ? (
        <ConfirmModal
          open={openConfirmModal}
          handleClose={handleCancelConfirmModal}
          handleCancel={handleCancelConfirmModal}
          handleAgree={handleCloseConfirmModal}
        />
      ) : (
        <>
          <SEO title={node.title} />
          <Header location={location} />
          <div className="landing-page" data-alias={node.alias}>
            <main id="main">
              <Landing node={node} {...props} />
            </main>
            <Footer />
          </div>
        </>
      )}
    </>
  )
}

LandingTemplate.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
