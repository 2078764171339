import React, { forwardRef } from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, isFunction } from 'lodash'
import { Button as MuiButton, Icon } from '@material-ui/core'
// Components
import SiteLink from '../SiteLink/SiteLink'
// Assets
import './_Button.scss'
import { whenPressingEnter } from '../../../lib'

const THEME = {
  default: 'default',
  blue: 'blue',
  lightBlue: 'light-blue',
  formTab: 'form-tab',
  back: 'back',
  edit: 'edit',
  clear: 'clear',
  upload: 'upload',
  add: 'add',
  icon: 'icon',
}

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  link: PropTypes.shape({
    uri: PropTypes.string,
    title: PropTypes.string,
  }),
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
}

const defaultProps = {
  className: '',
  theme: THEME.default,
  link: {
    uri: '',
    title: '',
  },
  onClick: null,
  label: '',
  disabled: false,
  icon: null,
  iconSize: '',
}

// eslint-disable-next-line react/display-name
const Button = forwardRef(
  (
    {
      className = '',
      onClick = null,
      label = '',
      theme = THEME.default,
      children,
      link,
      disabled,
      icon,
      iconSize,
      ...props
    },
    ref
  ) => {
    const linkData = defaultsDeep(link, defaultProps.link)
    const renderButtonContent = () => {
      return children || label || linkData.title
    }

    const renderAttributes = () => {
      const attributes = {}
      switch (theme) {
        case THEME.blue:
        case THEME.lightBlue:
          attributes.variant = 'outlined'
          break
        case THEME.back:
          attributes.variant = 'contained'
          attributes.startIcon = <Icon fontSize="small">chevron_left</Icon>
          break
        case THEME.edit:
          attributes.variant = 'outlined'
          attributes.size = 'small'
          attributes.startIcon = <Icon fontSize="small">edit</Icon>
          break
        case THEME.clear:
          attributes.variant = 'outlined'
          attributes.size = 'small'
          attributes.startIcon = <Icon fontSize="small">clear</Icon>
          break
        case THEME.upload:
          attributes.variant = 'outlined'
          attributes.startIcon = <Icon fontSize="small">publish</Icon>
          break
        case THEME.add:
          attributes.variant = 'outlined'
          attributes.startIcon = <Icon fontSize="small">add</Icon>
          break
        case THEME.icon:
          attributes.variant = 'contained'
          attributes.fontSize = iconSize || 'small'
          break
        default:
          attributes.variant = 'outlined'
      }

      if (isFunction(onClick)) {
        attributes.ref = ref
        attributes.onClick = onClick
        attributes.onKeyPress = (e) => whenPressingEnter(e, onClick)
      } else if (linkData.uri) {
        attributes.href = linkData.uri
      }

      return attributes
    }

    return (
      <>
        {isFunction(onClick) ? (
          <MuiButton
            {...props}
            {...renderAttributes()}
            className={`${className} mui-button`}
            data-theme={theme}
            disabled={disabled}
          >
            {renderButtonContent()}
          </MuiButton>
        ) : (
          <SiteLink
            {...props}
            href={linkData.uri}
            className={`${className} mui-button`}
            data-theme={theme}
            onClick={onClick || null}
          >
            {renderButtonContent()}
          </SiteLink>
        )}
      </>
    )
  }
)

// Export Props
Button.defaultProps = defaultProps
Button.propTypes = propTypes
// Export Component and Themes
Button.THEME = THEME
export default Button
