import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Components
import { Button } from '../../nav'
// Assets
import './_Teaser.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    ariaLabel: PropTypes.string,
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
    })
  }),
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    ariaLabel: '',
    link: {
      title: '',
      uri: '',
    },
  },
}

const Teaser = ({ data, ...props }) => {
  const { title, text, link, ariaLabel } = defaultsDeep(data, defaultProps.data)

  return (
    <article className="teaser">
      <h2
        className="teaser__title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div
        className="teaser__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {link && link.uri && (
        <Button
          className="teaser__link"
          link={link}
          theme={Button.THEME.blue}
          aria-label={`${ariaLabel}`}
        />
      )}
    </article>
  )
}

// Export Props
Teaser.defaultProps = defaultProps
Teaser.propTypes = propTypes
// Export Component
export default Teaser
