import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Components
import { SiteLink } from '../../nav'
// Assets
import './_ResourceCard.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    }),
    file: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      format: PropTypes.string,
      size: PropTypes.string
    })
  })
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    image: {
      alt: '',
      file: {}
    },
    file: {
      label: '',
      url: '',
      format: '',
      size: ''
    }
  }
}

const ResourceCard = ({ data, ...props }) => {
  const { title, text, image, file } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="resource-card">
      {title && (
        <div className="resource-card__title">{title}</div>
      )}
      <div className="resource-card__inner">
        <div className="resource-card__image">
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        </div>
        <div className="resource-card__content">
          {text && (
            <div className="resource-card__text">{text}</div>
          )}
          {file.url && (
            <SiteLink
              href={file.url}
              className="resource-card__link"
            >
              {file.label} | {file.format} | {file.size}
            </SiteLink>
          )}
        </div>
      </div>
    </div>
  )
}

// Export Props
ResourceCard.defaultProps = defaultProps
ResourceCard.propTypes = propTypes
// Export Component
export default ResourceCard
