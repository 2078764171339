import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Components
import { Accordion, CardsList } from '../../containers'
// Assets
import './_FAQ.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    cardsList: PropTypes.shape({}),
    accordionItems: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      text: PropTypes.string
    }))
  })
}

const defaultProps = {
  data: {
    title: '',
    cardsList: {},
    accordionItems: {
      label: '',
      text: ''
    }
  }
}

const FAQ = ({ data, ...props }) => {
  const { title, cardsList } = defaultsDeep(data, defaultProps.data)
  return (
    <section className="faq">
      <div className="faq__inner">
        <h2 className="faq__title">{title}</h2>
        <CardsList
          data={{
            topList: cardsList
          }}
        />
        <Accordion />
      </div>
    </section>
  )
}

// Export Props
FAQ.defaultProps = defaultProps
FAQ.propTypes = propTypes
// Export Component
export default FAQ
