import React, { useState } from 'react'
import { defaultsDeep, values } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { useI18n } from '../../../lib/hooks'
// Components
import { Button } from '../../nav'
// Assets
import './_PreFooter.scss'

const PRE_FOOTER_THEME = {
  default: 'default',
  healthcare: 'healthcare',
  text: 'text'
}

const propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.oneOf(values(PRE_FOOTER_THEME)),
    title: PropTypes.string,
    text: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    theme: PRE_FOOTER_THEME.default,
    title: '',
    text: ''
  },
}

const PreFooter = ({ data, ...props }) => {
  const { t } = useI18n()
  const { theme, title, text } = defaultsDeep(data, defaultProps.data)
  const [activeForm, setActiveForm] = useState(theme === PRE_FOOTER_THEME.healthcare ? 'healthcare' : 'patient')

  const switchForm = (key) => {
    setActiveForm(key)
  }

  return (
    <section className="pre-footer">
      <div className="pre-footer__inner" id="contact">
        {theme !== PRE_FOOTER_THEME.text && (
          <>
            <h2
              className="pre-footer__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="pre-footer__form-switcher">
              <h2 className="pre-footer__form-switcher-label">
                {t('contact.tabs.mainLabel')}
              </h2>
              <div className="pre-footer__form-switcher-buttons">
                {theme !== PRE_FOOTER_THEME.healthcare && (
                  <Button
                    className={activeForm === 'patient' ? 'active' : ''}
                    key="patient"
                    theme={Button.THEME.formTab}
                    label={t('contact.tabs.patientLabel')}
                    onClick={() => switchForm('patient')}
                  />
                )}
                <Button
                  className={activeForm === 'healthcare' ? 'active' : ''}
                  key="healthcare"
                  theme={Button.THEME.formTab}
                  label={t('contact.tabs.healthcareLabel')}
                  onClick={() => switchForm('healthcare')}
                />
              </div>
            </div>
            <div className="pre-footer__forms">
              {theme !== PRE_FOOTER_THEME.healthcare && (
                <div
                  className={`pre-footer__form ${activeForm === 'patient' ? 'active' : ''}`}
                  dangerouslySetInnerHTML={{ __html: t('contact.forms.patientForm') }}
                />
              )}
              <div
                className={`pre-footer__form ${activeForm === 'healthcare' ? 'active' : ''}`}
                dangerouslySetInnerHTML={{ __html: t('contact.forms.healthcareForm') }}
              />
            </div>
          </>
        )}
        <div
          className="pre-footer__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  )
}

// Export Props
PreFooter.defaultProps = defaultProps
PreFooter.propTypes = propTypes
// Export Component
export default PreFooter
