/* eslint-disable */
import React from 'react'
import getWindow from '../../../lib/getWindow'
// Assets
import './_Raw.scss'

export default function Raw({ type = '', comp = {} }) {
  const isDev = String(getWindow('location.origin') || '').indexOf('localhost') !== -1

  return (
    <>
      {isDev ? (
        <pre className="raw">
          <code className="language-json">
            // Missing Component Template for: {type}
            {'\n' + JSON.stringify(comp, null, '\t')}
          </code>
        </pre>
      ) : null}
    </>
  )
}
