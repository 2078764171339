import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_StepCard.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    title: '',
    text: '',
  },
}

const StepCard = ({ data, ...props }) => {
  const { title, text, image } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="step-card">
      {image && image.file && (
        <div className="step-card__image">
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        </div>
      )}
      <div className="step-card__title">
        {title}
      </div>
      <div
        className="step-card__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}

// Export Props
StepCard.defaultProps = defaultProps
StepCard.propTypes = propTypes
// Export Component
export default StepCard
