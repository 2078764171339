import { graphql, useStaticQuery } from 'gatsby'

/**
 * Retrieve site metadata, else use the original site settings as a fallback.
 *
 * @returns {any}
 */
const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          description
          author
          title
          siteUrl
          siteName
          twitterSite
        }
      }
    }
  `)

  return data && data.site ? data.site.siteMetadata : {}
}

export default useSiteMetadata
