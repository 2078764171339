import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Data
// Components
// import { SiteLink } from '../../nav'
// Assets
import './_SpacerPreview.scss'

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    size: PropTypes.string,
    image: PropTypes.shape({}),
    hoverImage: PropTypes.shape({}),
    selected: PropTypes.string,
    color: PropTypes.string,
  }),
  choiceSpacer: PropTypes.func
}

const defaultProps = {
  data: {
    id: '',
    size: '',
    image: [],
    hoverImage: [],
    selected: '',
    color: '',
  },
  choiceSpacer: null
}

const SpacerPreview = ({ data, ...props }) => {
  const { id, size, image, selected, hoverImage, color } = defaultsDeep(data, defaultProps.data)
  const spacerImage = getImage(image.file)
  const spacerHoverImage = getImage(hoverImage.file)
  return (
    <div
			className={`spacer-preview ${selected === id ? 'active' : ''}`}
			onClick={() => props.choiceSpacer(id)}
			onKeyPress={() => props.choiceSpacer(id)}
			role="button"
			tabIndex="0"
      data-color={color}
		>
      <div className="spacer-preview__image-container">
        <div className="spacer-preview__image">
          <GatsbyImage alt={image.alt} image={spacerImage} />
        </div>
        <div className="spacer-preview__hover-image">
          <GatsbyImage alt={hoverImage.alt} image={spacerHoverImage} />
        </div>
      </div>
      <div className="spacer-preview__label">{size}</div>
    </div>
  )
}

// Export Props
SpacerPreview.defaultProps = defaultProps
SpacerPreview.propTypes = propTypes
// Export Component
export default SpacerPreview