import React from 'react'
import { defaultsDeep } from 'lodash'
import * as PropTypes from 'prop-types'
// Components
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { Button } from '../../nav'
// Data
import { useI18n } from '../../../lib/hooks'
// Assets

const propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleCancel: PropTypes.func,
  handleAgree: PropTypes.func,
}

const defaultProps = {
  open: false,
  handleClose: null,
  handleCancel: null,
  handleAgree: null,
}

const ConfirmModal = ({ ...props }) => {
  const { open, handleClose, handleCancel, handleAgree } = defaultsDeep(props, defaultProps)
  const { t } = useI18n()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <DialogContentText>
          {t('confirmModal.text')}
        </DialogContentText>
        <DialogActions>
          <Button theme={Button.THEME.default} onClick={handleCancel}>
            {t('confirmModal.cancel')}
          </Button>
          <Button theme={Button.THEME.blue} onClick={handleAgree}>
            {t('confirmModal.agree')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

// Export Props
ConfirmModal.defaultProps = defaultProps
ConfirmModal.propTypes = propTypes
// Export Component
export default ConfirmModal
