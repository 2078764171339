import React, { useEffect, useState } from 'react'
import { noop, defer, delay, forEach, isEqual } from 'lodash'
import { useFormik } from 'formik'
import * as PropTypes from 'prop-types'
import * as Yup from 'yup'
// Data
import { Forms } from '../../../schema/types'
import { consoleBadge, consoleStyles } from '../../../lib'
// Components
import FormField from '../FormField/FormField'
import FormActions from './FormActions'
//Assets
import './_Form.scss'

const con = consoleBadge('useForm', consoleStyles.hooks)

const propTypes = {
  ...Forms.FormPropTypes,
  autoComplete: PropTypes.bool,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

const defaultProps = {
  ...Forms.FormDefaultProps,
  autoComplete: '',
  className: '',
  onSubmit: (formValues, { setSubmitting }) => {
    setTimeout(() => {
      // con.log('Form submit', JSON.stringify(formValues, null, 2))
      setSubmitting(false)
    }, 400)
  },
}

const obtainFieldData = (fields) => {
  // con.log('rebuilding form field data')
  const init = {}
  const schema = {}
  forEach(fields, (formField, name) => {
    init[name] = formField?.defaultValue ?? ''
    schema[name] = formField?.validator ?? ''
  })
  return { init, schema }
}

const renderField = (name, { subfields, validator, ...field }, formik) => {
  let input

  const inputProps = {
    name,
    formik,
    ...field,
  }
  input = <FormField key={`formInput_${name}`} {...inputProps} />

  if (subfields) {
    const subs = []
    forEach(field.subfields, (subfield, subfieldName) => {
      subs.push(renderField(subfield, subfieldName, formik))
    })

    input = (
      <div className="form-field__container" data-name={name}>
        {input}
        {subs}
      </div>
    )

    // con.log('subfields', subfields)
  }

  // con.log('renderInput', name, input, field)

  return input
}

const useForm = (
  { fields = [], className = '', actions = [], content = {}, onSubmit = noop, autoComplete },
  formikProps = null
) => {
  const [state, setState] = useState(()=>{
    return {
      fields,
      content,
      actions,
      ...obtainFieldData(fields),
      inputs: [],
      data: {
        errors: {},
        touched: {},
      },
    }
  })
  const { header, resendSms, middle, footer } = state.content
  const formik = useFormik({
    initialValues: state.init,
    validationSchema: Yup.object().shape(state.schema),
    onSubmit: (values) => {
      // con.log('formSubmit', values, formik)
      delay(() => onSubmit(values, formik),500)
    },
    formikProps,
  })

  /**
   * State supporting checks.
   */
  // Fields can only be updated post load, never replace the original fields to reduce effort.
  const setFields = (newFields) =>
    defer(() => {
      if (!isEqual(newFields, state.fields)) {
        setState({ ...state, fields: newFields, ...obtainFieldData(newFields) })
      }
    })

  // Actions can only be updated post load, never replace the original fields to reduce effort.
  const setActions = (newActions) =>
    defer(() => {
      if (!isEqual(newActions, state.actions)) {
        setState({ ...state, actions: newActions })
      }
    })

  // Refresh content changes
  useEffect(() => {
    defer(() => {
      if (!isEqual(content, state.content)) {
        setState({ ...state, content })
      }
    })
  }, [content])

  useEffect(() => {
    const data = {
      errors: formik.errors,
      touched: formik.touched,
    }
    if (!state.inputs.length || !isEqual(data, state.data)) {
      // con.log('running renderField on inputs', data)
      const inputs = []
      forEach(state.fields, (field, name) => {
        inputs.push(renderField(name, field, formik))
      })
      setState({
        ...state,
        inputs,
        data,
      })
    }
  }, [state.fields, formik.errors])

  const render = state.inputs.length ? (
    <form
      className={`${className} form`}
      // Determine whether or not this form should allow auto-completion of fields.
      autoComplete={autoComplete ? 'on' : 'off'}
    >
      <div className="form__content">
        {header && <header className="form__header">{header}</header>}
        <div className="form__fields">{state.inputs}</div>
        {resendSms && <div className="resend-sms">{resendSms}</div>}
        {middle && <section className="form__middle">{middle}</section>}
      </div>
      <div className="form__actions">
        <FormActions actions={actions} formik={formik} />
      </div>
      {footer && <footer className="form__footer">{footer}</footer>}
    </form>
  ) : null

  // con.log('render', state, { values: formik.values, errors: formik.errors })
  // eslint-disable-next-line no-debugger
  // debugger

  return { context: formik, field: state.fields, setFields, setActions, render }
}

useForm.defaultProps = defaultProps
useForm.propTypes = propTypes
export default useForm
