import React from 'react'
import { defaultsDeep, uniqueId } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { COMPONENT_TYPES } from '../../../schema/types'
// Components
import { Text } from '../../cards'
import { Raw } from '../../meta'
import Accordion from '../Accordion/Accordion'
import CardsList from '../CardsList/CardsList'

const propTypes = {
  data: PropTypes.shape({}),
}

const defaultProps = {
  data: {},
}

const TabContent = ({ data, ...props }) => {
  const { content: components } = defaultsDeep(data, defaultProps)
  const formatted = []
  components.forEach((component = {}, index) => {
    const componentData = { ...component }
    const { componentType } = component
    let comp = null
    const key = uniqueId(`${componentType}-${index}`)
    switch (componentType) {
      case COMPONENT_TYPES.text:
        comp = <Text key={key} data={componentData} />
        break
      case COMPONENT_TYPES.accordion:
        comp = <Accordion key={key} />
        break
      case COMPONENT_TYPES.cardsList:
        comp = <CardsList key={key} data={componentData} />
        break
    }

    if (comp) {
      formatted.push(comp)
    } else {
      formatted.push(<Raw key={key} type={componentType} comp={component} />)
    }
  })

  return (
    <div className="tab-content">{formatted}</div>
  )
}

// Export Props
TabContent.defaultProps = defaultProps
TabContent.propTypes = propTypes
// Export Component
export default TabContent
