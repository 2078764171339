import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { useI18n } from '../hooks'

/**
 * List of all Spacer Tabs data.
 *
 * @returns {any}
 */
const useSpacerList = () => {
  const { language, defaultLanguage } = useI18n()
  const data = useStaticQuery(graphql`
		query spacerTabsQuery {
      allTabsJson {
				edges {
					node {
            language
						spacers {
							id
							tabsData {
								label
								content {
									componentType
									theme
									topList {
										columnCount
										items {
											type
											title
											text
											image {
												alt
												file {
													childImageSharp {
														gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
													}
												}
											}
											file {
												label
												url
												format
												size
											}
										}
									}
									bottomList {
										columnCount
										items {
											type
											text
											video
											file {
												label
												url
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)

  const dataResult = get(data, 'allTabsJson.edges', [])
  const result = dataResult.find(({ node: item }) => item.language === language) || dataResult.find(({ node: item }) => item.language === defaultLanguage)
  return result && result.node && result.node.spacers ? result.node.spacers : []
}

export default useSpacerList