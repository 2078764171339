import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { DeliveryCard, Text } from '../../cards'
import Grid from '../Grid/Grid'
import { Button } from '../../nav'
// Assets
import './_DeliveryList.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      subText: PropTypes.string,
    }),
    deliveryItems: PropTypes.arrayOf(PropTypes.shape({
      chart: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      }),
      text: PropTypes.string,
      statistics: PropTypes.shape({
        text: PropTypes.string,
        image: PropTypes.shape({
          alt: PropTypes.string,
          file: PropTypes.shape({})
        }),
        bulletins: PropTypes.arrayOf(PropTypes.shape({
          copy: PropTypes.string,
          key: PropTypes.string
        }))
      })
    }))
  }),
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: '',
      subText: ''
    },
    deliveryItems: {
      chart: null,
      text: '',
      statistics: null,
    }
  },
}

const DeliveryList = ({ data, ...props }) => {

  const { link, topText, bottomText, deliveryItems, anchor } = defaultsDeep(data, defaultProps.data)

  const renderResource = (delivery, index) => (
    <DeliveryCard key={uniqueId(`delivery-list__item-${index}`)} data={delivery} />
  )

  return (
    <section className="delivery-list" id={anchor}>
      <div className="delivery-list__inner">
        {topText.title && (
          <>
            <div className="delivery-list__top-text">
              <Text data={topText}/>
            </div>
            <div dangerouslySetInnerHTML={{ __html: topText.subText }} className="delivery-list__sub-text"/>
          </>
        )}

        {deliveryItems && deliveryItems.length && (
          <Grid
            data={{
              gridItems: deliveryItems.map(renderResource),
              itemsPerColumn: 3,
              needsContainer: false,
            }}
          />
        )}

        <div className="delivery-list__bottom">
          {link && (
            <Button
              link={link}
              theme={Button.THEME.blue}
            />
          )}
          {bottomText.text && (
            <div className="delivery-list__bottom-text">
              {bottomText.text}
            </div>
          )}
        </div>

      </div>
    </section>
  )
}

// Export Props
DeliveryList.defaultProps = defaultProps
DeliveryList.propTypes = propTypes
// Export Component
export default DeliveryList