import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_DeliveryCard.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    })
  })
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    image: {
      alt: '',
      file: {}
    }
  }
}

const DeliveryCard = ({ data, ...props }) => {
  const { text, chart, statistics } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="delivery-card">

      {statistics && (
        <div className="delivery-card__statistics">
          <GatsbyImage alt={statistics.image.alt} image={getImage(statistics.image.file)} className="delivery-card__image"/>
          <div className="delivery-card__sample">{statistics.text}</div>
          <ul className="delivery-card__bulletin">
            {statistics.bulletins.map(
              (bulletin)=>(<li key={bulletin.key} className="delivery-card__list"><div className="delivery-card__item">{bulletin.copy}</div></li>)
            )}
          </ul>
        </div>
      )}

      {chart && (
        <div className="delivery-card__chart"><GatsbyImage alt={chart.alt} image={getImage(chart.file)} /></div>
      )}

      {text && (
        <div className="delivery-card__title"  dangerouslySetInnerHTML={{ __html:text }}/>
      )}

    </div>
  )
}

// Export Props
DeliveryCard.defaultProps = defaultProps
DeliveryCard.propTypes = propTypes
// Export Component
export default DeliveryCard
