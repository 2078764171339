/* eslint-disable react/destructuring-assignment */
/* no-nested-ternary */
import React from 'react'
import * as PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useI18n } from '../../../lib/hooks'
import { getWindow } from '../../../lib'
import { useSiteMetadata } from '../../../lib/static'

/**
 *
 * @param {string} description
 * @param {object} meta
 * @param {string} title
 * @returns
 */
const SEO = ({ description, meta, title }) => {
  const siteMetadata = useSiteMetadata()
  const { language } = useI18n()
  const location = getWindow('location') || {}
  const pageHref = location.href || ''
  const metaTitle = title || siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const metaAuthor = siteMetadata.author

  let metaLocale
  // To do: need to refine later
  switch (language) {
    case 'ca-fr':
      metaLocale = 'fr_CA'
      break
    case 'en':
      metaLocale = 'en_CA'
      break
    default:
      metaLocale = 'en_UK'
  }

  const pageMeta = [
    {
      name: 'description',
      content: metaDescription,
    },
    {
      property: 'og:title',
      content: metaTitle,
    },
    {
      property: 'og:description',
      content: metaDescription,
    },
    {
      property: 'og:locale',
      content: metaLocale,
    },

    {
      property: 'og:url',
      content: pageHref,
    },

    {
      property: 'og:site_name',
      content: siteMetadata.siteName,
    },

    // Enable and update when content is ready
    // {
    //   name: 'twitter:card',
    //   content: 'summary_large_image',
    // },
    // {
    //   name: 'twitter:site',
    //   content: siteMetadata.twitterSite,
    // },

    {
      name: 'twitter:creator',
      content: metaAuthor,
    },

    {
      name: 'twitter:title',
      content: metaTitle,
    },

    {
      name: 'twitter:description',
      content: metaDescription,
    },

  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={`${title} | ${siteMetadata.siteName}`}
      titleTemplate="%s"
      meta={pageMeta}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  description: '',
  article: null,
  author: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.shape({}),
  author: PropTypes.shape({}),
}

export default SEO
