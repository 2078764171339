import { noop, values } from 'lodash'
import * as PropTypes from 'prop-types'
import useI18n from '../../lib/hooks/useI18n'

export const ErrorTimeout = 6000

export const FormPropTypes = {
  field: PropTypes.shape({}),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  content: PropTypes.shape({
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    middle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  }),
}

export const FormDefaultProps = {
  fields: {},
  actions: [],
  content: {
    header: null,
    middle: null,
    footer: null,
  },
}

export const FieldTypes = {
  text: 'text',
  email: 'email',
  tel: 'tel',
  password: 'password',
  textarea: 'textarea',
  checkbox: 'checkbox',
  date: 'date',
  select: 'select',
  switch: 'switch',
  dropdown: 'select',
  radioGroup: 'radiogroup',
  hidden: 'hidden'
}

export const SelectStyles = {
  multiple: 'multiple',
  checkboxes: 'checkboxes',
  multipleCheckboxes: 'multiple-checkboxes',
  autocomplete: 'autocomplete',
}

export const LabelPlacement = {
  end: 'end',
  start: 'start',
  top: 'top',
  bottom:'bottom'
}

export const FormikContextFields = {
  dirty: 'dirty',
  errors: 'errors',
  isSubmitting: 'isSubmitting',
  isValid: 'isValid',
  isValidating: 'isValidating',
  status: 'status',
  submitCount: 'submitCount',
  values: 'values',
  setValues: 'setValues',
}

export const FormFieldAutoCompleteOptionsProp = PropTypes.shape({
  forceLoad: PropTypes.bool,
  loader: PropTypes.func,
  transpose: PropTypes.func,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  reset: PropTypes.bool,
  freeSolo: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
})

export const FormFieldAutoCompleteDefaults = {
  forceLoad: false,
  loader: noop,
  transpose: (value) => value,
  reset: false,
  freeSolo: false,
  openOnFocus: true,
  onChange: noop,
  onBlur: null,
  // Handled dynamically.
  // valueKey: '',
  // labelKey: '',
}

export const FormFieldProps = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(values(FieldTypes)).isRequired,
  label: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  valueChanged: PropTypes.func,
  autoCompleteOptions: FormFieldAutoCompleteOptionsProp,
  autoComplete: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  displayed: PropTypes.bool,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  valid: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  descriptor: PropTypes.string,
  icon: PropTypes.element,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

export const FormFieldDefaults = {
  disabled: false,
  onBlur: noop,
  valid: undefined,
  options: [],
}

export const Provinces = [
  {
    value: 'AB',
    label: 'Alberta',
  },
  {
    value: 'BC',
    label: 'British Columbia',
  },
  {
    value: 'QC',
    label: 'Quebec',
  },
  {
    value: 'MB',
    label: 'Manitoba',
  },
  {
    value: 'NS',
    label: 'Nova Scotia',
  },
  {
    value: 'NB',
    label: 'New Brunswick',
  },
  {
    value: 'ON',
    label: 'Ontario',
  },
  {
    value: 'PE',
    label: 'Prince Edward Island',
  },
  {
    value: 'SK',
    label: 'Saskatchewan',
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador',
  },
  {
    value: 'NT',
    label: 'Northwest Territories',
  },
  {
    value: 'NU',
    label: 'Nunavut',
  },
  {
    value: 'YT',
    label: 'Yukon',
  },
]

export const HeardAboutUs = () => {
  const { t } = useI18n()
  return [
    {
      value: '',
      label: t('steps.fields.chooseAnOption'),
    },
    {
      value: 'Google Search Result',
      label: 'Google Search Result',
    },
    {
      value: 'Google Ad',
      label: 'Google Ad',
    },
    {
      value: 'Other Online Ad',
      label: 'Other Online Ad',
    },
    {
      value: 'Online Review/Video',
      label: 'Online Review/Video',
    },
    {
      value: 'App Store',
      label: 'App Store',
    },
    {
      value: 'Referral Code/Friend',
      label: 'Referral Code/Friend',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ]
}

export const Banks = () => {
  const { t } = useI18n()
  return [
    {
      value: null,
      label: t('page.eTransfer.deposit.bankSelect'),
    },
    {
      value: 'BMO',
      label: 'BMO',
    },
    {
      value: 'CIBC',
      label: 'CIBC',
    },
    {
      value: 'Desjardins',
      label: 'Desjardins',
    },
    {
      value: 'HSBC',
      label: 'HSBC',
    },
    {
      value: 'Meridian',
      label: 'Meridian',
    },
    {
      value: 'National Bank',
      label: 'National Bank',
    },
    {
      value: 'RBC',
      label: 'RBC',
    },
    {
      value: 'Scotiabank',
      label: 'Scotiabank',
    },
    {
      value: 'Simplii',
      label: 'Simplii',
    },
    {
      value: 'Tangerine',
      label: 'Tangerine',
    },
    {
      value: 'TD',
      label: 'TD',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ]
}

export const YesNoOption = () => {
  const { t } = useI18n()
  return [
    {
      value: '',
      label: t('steps.fields.chooseAnOption'),
    },
    {
      label: t('steps.options.yes'),
    },
    {
      label: t('steps.options.no'),
    },
  ]
}
