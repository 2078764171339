import React from 'react'
import * as PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { size, uniqueId, noop, defaultsDeep } from 'lodash'
// Data
import { useI18n } from '../../../lib/hooks'
// Components
import { Button } from '../../nav'

const propTypes = {
  data: PropTypes.shape({
    buttonTheme: PropTypes.string,
    buttonLabel: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        theme: PropTypes.string,
        type: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        displayed: PropTypes.bool,
        onClick: PropTypes.func,
        loading: PropTypes.bool,
      })
    ),
  }),
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    isValidating: PropTypes.bool,
    handleSubmit: PropTypes.func,
  }),
}

const defaultProps = {
  data: {
    buttonTheme: '',
    buttonLabel: '',
    actions: [],
  },
  formik: {
    isSubmitting: false,
    isValidating: false,
    handleSubmit: noop,
  },
}

const FormActions = ({
  actions,
  formik: { isSubmitting, handleSubmit, isValidating },
}) => {
  const { t } = useI18n()
  const defaultActionProps = {
    label: t('Submit'),
    theme: Button.THEME.blue,
    disabled: false,
    displayed: true,
  }
  const defaultActions = [
    {
      theme: defaultActionProps.theme,
      label: defaultActionProps.label,
      type: 'submit',
      disabled: false,
      displayed: true,
      onClick: noop,
      loading: false,
    },
  ]
  const actionList = size(actions) ? actions : defaultActions
  return actionList.map((item, index) => {
    defaultsDeep(item, defaultActionProps)
    switch (item.type) {
      case 'button':
        return (
          <Button
            key={uniqueId(`action-item-${index}_`)}
            className="button form__action-button"
            theme={item.theme}
            data-displayed={item.displayed}
            type="button"
            onClick={item.onClick}
            disabled={item.disabled || isSubmitting || isValidating}
          >
            <span className="form__action-label">{item.label}</span>
          </Button>
        )
      default:
        return (
          <Button
            key={uniqueId(`action-item-${index}_`)}
            className="button form__action-button"
            theme={item.theme}
            data-displayed={item.displayed}
            type="submit"
            onClick={handleSubmit}
            disabled={item.disabled || isSubmitting || isValidating}
            data-loading={isSubmitting || item.loading}
          >
            {
              (isSubmitting || item.loading) && (
              <span className="form__action-loader">
                <CircularProgress size={30} />
              </span>
              )}
            <span className="form__action-label">{item.label}</span>
          </Button>
        )
    }
  })
}

FormActions.defaultProps = defaultProps
FormActions.propTypes = propTypes
export default FormActions
