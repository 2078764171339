import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { SideEffectCard, Text } from '../../cards'
import { Button } from '../../nav'
import Grid from '../Grid/Grid'

// Assets
import './_SideEffectsList.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    chart: PropTypes.shape({
      desktop: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      }),
      mobile: PropTypes.arrayOf(PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      })),
    }),
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string
    })
  })
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    chart: {
      desktop: {
        alt: '',
        file: {}
      },
      mobile: {
        alt: '',
        file: {}
      }
    },
    link: {
      title: '',
      uri: ''
    }
  }
}

const SideEffectsList = ({ data, ...props }) => {
  const { topText, chart, link, anchor } = defaultsDeep(data, defaultProps.data)

  const renderCard = (chartMobile, index) => (
    <SideEffectCard key={uniqueId(`side-effects-list__item-${index}`)} data={chartMobile} />
  )

  return (
    <section className="side-effects-list" id={anchor}>
      <div className="side-effects-list__inner">
        {topText.title && (
          <div className="side-effects-list__top-text">
            <Text data={topText} />
          </div>
        )}
        <div className="side-effects-list__chart side-effects-list__image side-effects-list__desktop">
          {chart && (
            <GatsbyImage alt={chart.desktop.alt} image={getImage(chart.desktop.file)}/>
          )}
        </div>
        <div className="side-effects-list__chart side-effects-list__mobile">
          {chart && chart.mobile.length && (
            <Grid
              data={{
                gridItems: chart.mobile.map(renderCard),
                itemsPerColumn: 2,
                needsContainer: false,
              }}
            />
          )}
        </div>
        {link && (
          <div className="side-effects-list__link">
            <Button
              link={link}
              theme={Button.THEME.blue}
            />
          </div>
        )}
      </div>
    </section>
  )
}

// Export Props
SideEffectsList.defaultProps = defaultProps
SideEffectsList.propTypes = propTypes
// Export Component
export default SideEffectsList