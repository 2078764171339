import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { useI18n } from '../hooks'

/**
 * List of all Spacer products.
 *
 * @returns {any}
 */
const useSpacerList = () => {
  const { language } = useI18n()
  const data = useStaticQuery(graphql`
		query spacerListQuery {
      allSpacersJson(sort: {fields: id, order: ASC}) {
        edges {
          node {
            id
            color
            additionalSpacerOptions
            content {
              language
              name
              subtitle
              description
              previewSize
              size
              price
              additionalSpacerText
              buyLink {
                title
                uri
              }
              flowLink {
                title
                uri
              }
              linksText
              previewImage {
                alt
                file {
                  childImageSharp {
                    gatsbyImageData(width: 146, height: 150, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
              }
              selectedImage {
                alt
                file {
                  childImageSharp {
                    gatsbyImageData(width: 146, height: 150, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
              }
              images {
                alt
                file {
                  childImageSharp {
                    gatsbyImageData(width: 550, height: 330, formats: [AUTO, WEBP])
                  }
                }
                tooltips {
                  id
                  left
                  top
                  copy
                  image {
                    alt
                    file {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
		}
	`)

  const dataResult = get(data, 'allSpacersJson.edges', [])
  const dataResultWithContent = dataResult.map(({ node: item }) => {
    const content = item.content.filter((contentData) => contentData.language === language)[0]
    if (content) {
      return { ...item, content }
    }
    return null
  })

  return dataResultWithContent.filter((item) => item !== null)
}

export default useSpacerList