import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Data
// Components
// import { SiteLink } from '../../nav'
// Assets
import './_TextWithVideo.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    video: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    title: '',
    text: '',
    video: ''
  },
}

const TextWithVideo = ({ data, ...props }) => {
  const { title, text, video } = defaultsDeep(data, defaultProps.data)

  return (
    <div className="text-with-video">
      <div className="text-with-video__inner">
        <div className="text-with-video__content">
          <h2 className="text-with-video__title">{title}</h2>
          <div
            className="text-with-video__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        <div
          className="text-with-video__video"
          dangerouslySetInnerHTML={{ __html: video }}
        />
      </div>
    </div>
  )
}

// Export Props
TextWithVideo.defaultProps = defaultProps
TextWithVideo.propTypes = propTypes
// Export Component
export default TextWithVideo