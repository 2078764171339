import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_Testimonial.scss'
import { ReactComponent as TestimonialIcon } from '../../../assets/images/icons/testimonial.svg'


const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    title: '',
    subtitle: ''
  },
}

const Testimonial = ({ data, ...props }) => {
  const { title, subtitle, image } = defaultsDeep(data, defaultProps.data)

  return (
    <article className="testimonial">
      <div className="testimonial__outer">
        <TestimonialIcon />
        <h2 className="testimonial__title">{title}</h2>
        <div className="testimonial__subtitle">{subtitle}</div>
      </div>
      <div className="testimonial__image">
        {image && (
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        )}
      </div>
    </article>
  )
}

// Export Props
Testimonial.defaultProps = defaultProps
Testimonial.propTypes = propTypes
// Export Component
export default Testimonial
