import React, { useState, createContext, useEffect } from 'react'
import { delay } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { getWindow, scrollTo } from '../../../lib'

export const SpacerChoiceContext = createContext(null)

const propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeSpacer: PropTypes.string,
      spacerFormOptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
      ])
    })
  })
}

const defaultProps = {
  location: {
    state: {
      activeSpacer: '',
      spacerFormOptions: ''
    }
  }
}

export default function SpacerChoiceContextProvider({ location, children }) {
  const win = getWindow()
  const spacer = location?.state?.activeSpacer || '5'
  const { firstQuestion, secondQuestion, thirdQuestion } = location?.state?.spacerFormOptions || {
    firstQuestion: 'fourth_option',
    secondQuestion: 'first_option',
    thirdQuestion: 'first_option'
  }
  const [currentSpacer, setCurrentSpacer] = useState(spacer)
  const [spacerOptions, setSpacerOptions] = useState({
    firstQuestionDefaultValue: firstQuestion,
    secondQuestionDefaultValue: secondQuestion,
    thirdQuestionDefaultValue: thirdQuestion
  })

  // Remove states from location.
  /*if (win?.location) {
    const url = new URL(win.location)
    win.history.pushState({ ...location?.state, activeSpacer: '', spacerFormOptions: '' }, '', url.href)
  }*/

  const scrollAfterSpacerChange = (newSpacer) => {
    delay(() => setCurrentSpacer(newSpacer), 50)
    delay(() => {
      const spacerListComponent = win.document.querySelector('.spacer-choice__main-spacer')
      scrollTo.top(spacerListComponent.offsetTop)
    }, 300)
  }

  useEffect(() => {
    return () => {
      setCurrentSpacer('5')
    }
  }, [])

  return (
    <SpacerChoiceContext.Provider
      value={{
        currentSpacer,
        setCurrentSpacer: scrollAfterSpacerChange,
        spacerOptions,
        setSpacerOptions
      }}
    >
      {children}
    </SpacerChoiceContext.Provider>
  )
}

// Export Props
SpacerChoiceContextProvider.defaultProps = defaultProps
SpacerChoiceContextProvider.propTypes = propTypes