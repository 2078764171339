/* global arguments */
/* eslint-disable react/jsx-props-no-spreading,no-unused-vars,jsx-a11y/label-has-associated-control,max-len,react/destructuring-assignment,react/jsx-no-duplicate-props */
import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { defaultsDeep, uniqueId, omit, isFunction, pick, isObject, isEqual } from 'lodash'
// Components
import { FormHelperText } from '@material-ui/core'
import { Forms } from '../../../schema/types'
import Select from './Select'
import TextField from './TextField'
import Memoized from '../../layout/Memoized/Memoized'
// Data
import { consoleBadge, consoleStyles } from '../../../lib'
// Assets
import './_FormField.scss'

const con = consoleBadge('FormField', consoleStyles.forms)

const propTypes = Forms.FormFieldProps
const defaultProps = Forms.FormFieldDefaults

const FormField = ({ formik = {}, ...compProps }) => {
  const fieldRef = createRef()
  const [state, setState] = useState(compProps)
  // Primary field data
  const {
    ariaLabel,
    autoCompleteOptions,
    valueChanged = null,
    displayed = true,
    inputProps = {},
    waitForTouched = true,
    maxDate,
    name,
    type: inputType = Forms.FieldTypes.text,
    onBlur,
    icon = null,
    descriptor,
    helpText = '',
    caption,
    labelPlacement,
    afterFieldText,
    ...props
  } = state
  // Supporting field data
  const { id = name, label = name, placeholder = '', disabled, options } = props
  // Formik field data.
  const [data, setData] = useState({
    value: formik.values[name],
    touched: formik.touched[name],
    error: formik.errors[name],
  })
  const { value, touched, error } = data
  // Pull in other possible inputProps that were external previously.
  Object.assign(inputProps, {
    'aria-label': inputProps.ariaLabel || ariaLabel || placeholder,
  })
  delete inputProps.ariaLabel

  const hasError
    = ((touched && waitForTouched) || !waitForTouched) && value !== undefined && !!error

  // Define the commonly shared properties the final input will leverage.
  const fieldProps = {
    id,
    ref: fieldRef,
    className: 'form-field__field',
    name,
    placeholder,
    label,
    onChange: formik.handleChange,
    onBlur, //: formik.onBlur,
    value,
  }

  /**
   * Limit which fields ensure their label reacts to the value being filled.
   */
  const addFilledStyleWithValue = () => {
    switch (inputType) {
      case Forms.FieldTypes.text:
      case Forms.FieldTypes.email:
      case Forms.FieldTypes.password:
        // If the value was entered, ensure the filled class shows on the input label...
        if (value) {
          const { current } = fieldRef
          const labelEl = current.closest('.form-field').querySelector('label')
          labelEl.classList.add('MuiInputLabel-filled')
          // con.log('forcing label due to value')
        }
        break
    }
  }

  // Cleanly handle adding/removing disabled attribute to input.
  useEffect(() => {
    const { current } = fieldRef
    // AutoComplete handles disabled in the wrapper, not the input.
    if (current) {
      if (disabled) {
        current.setAttribute('disabled', 'disabled')
      } else {
        current.removeAttribute('disabled')
      }
    }
  }, [disabled])

  // If provided, fire the value change reaction method
  useEffect(() => {
    if (isFunction(valueChanged)) {
      valueChanged(value)

      addFilledStyleWithValue()
    }
    // getVariant()
  }, [value])

  useEffect(() => {
    const { current } = fieldRef
    current && current.setAttribute('data-valid', current.dataset.valid)
  }, [fieldRef])

  useEffect(() => {
    if (!isEqual(state, compProps)) {
      // con.log(`compProps changed on ${name}`)
      setState(compProps)
    }
  }, [compProps])

  useEffect(() => {
    const newData = {
      value: formik.values[name],
      touched: formik.touched[name],
      error: formik.errors[name],
    }
    if (!isEqual(newData, data)) {
      // con.log(`formik data changed on ${name}`)
      setData(newData)
    }
  }, [formik])

  const renderField = useCallback(() => {
    let comp

    switch (inputType) {
      case Forms.FieldTypes.text:
      case Forms.FieldTypes.email:
      case Forms.FieldTypes.password:
        comp = (
          <TextField
            {...fieldProps}
            type={inputType}
            label={label}
            inputProps={inputProps}
            disabled={disabled}
            icon={icon}
          />
        )
        break
      case Forms.FieldTypes.textarea:
        comp = (
          <TextField
            {...fieldProps}
            type={inputType}
            inputProps={inputProps}
            label={label}
            multiline
            rows={1}
            rowsMax={4}
          />
        )
        break
      case Forms.FieldTypes.select:
        comp = <Select {...props} {...fieldProps} label={label} />
        break
      case Forms.FieldTypes.hidden:
        comp = <TextField {...props} {...fieldProps} type={inputType} />
        break
      default:
      // con.warn('FormInput -- invalid field type in', props)
    }

    // con.log(`renderField ${name}`, comp)

    return comp
  },[data])

  // con.log(`${name} render`, compProps)
  // eslint-disable-next-line no-debugger
  // debugger

  return (
    <Memoized deps={[state, data]}>
      <div
        className="form-field"
        data-name={name}
        data-input-type={inputType}
        data-error={hasError}
        data-valid={!hasError}
        data-visible={displayed}
      >
        {descriptor && inputType !== Forms.FieldTypes.radioGroup && (
          <div
            className="form-field__descriptor"
            dangerouslySetInnerHTML={{ __html: descriptor }}
          />
        )}
        {caption && <div className="field-caption">{caption}</div>}
        {renderField()}
        <FormHelperText id={`${id}-helper-text`}>
          {!hasError && !!helpText && <span>{helpText}</span>}
          {hasError && <span>{error}</span>}
        </FormHelperText>
        {afterFieldText && (
          <div
            className="form-field__bottom-text"
            dangerouslySetInnerHTML={{ __html: afterFieldText }}
          />
        )}
      </div>
    </Memoized>
  )
}

// Exports
FormField.defaultProps = defaultProps
FormField.propTypes = propTypes
export default FormField
