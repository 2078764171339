const COMPONENT_TYPES = {
  accordion: "accordion",
  cardsList: "cards-list",
  faq: "faq",
  hero: "hero",
  imageWithLink: "image-with-link",
  infoData: "info-data",
  overcomeList: "overcome-list",
  sideEffectsList: "side-effects-list",
  resourcesList: "resources-list",
  deliveryList: "delivery-list",
  mdiList: "mdi-list",
  spacerChoice: "spacer-choice",
  teasersList: "teasers-list",
  testimonialsList: "testimonials-list",
  text: "text",
  textWithIcon: "text-with-icon",
  textWithListColumns: "text-with-list-columns",
  textWithVideo: "text-with-video",
  videoHero: "video-hero",
  webinarsList: "webinars-list",
  preFooter: "pre-footer",
  imageWithLink: "image-with-link",
}

export default COMPONENT_TYPES
