import { graphql, useStaticQuery } from 'gatsby'
import { get } from 'lodash'
import { useI18n } from '../hooks'

/**
 * List of all Spacer Faq items.
 *
 * @returns {any}
 */
const useSpacerAccordionFaq = () => {
  const { language, defaultLanguage } = useI18n()
  const data = useStaticQuery(graphql`
		query spacerAccordionFaqQuery {
			allFaqJson {
				edges {
					node {
						language
						accordionItems {
							label
							text
						}
					}
				}
			}
		}
	`)

  const dataResult = get(data, 'allFaqJson.edges', [])
  const result = dataResult.find(({ node: item }) => item.language === language) || dataResult.find(({ node: item }) => item.language === defaultLanguage)
  return result && result.node && result.node.accordionItems ? result.node.accordionItems : []
}

export default useSpacerAccordionFaq