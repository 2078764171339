import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { MdiCard, Text } from '../../cards'
import Grid from '../Grid/Grid'
// Assets
import './_MdiList.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    iconItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      })
    }))
  }),
  location: PropTypes.shape({})
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    iconItems: {
      title: '',
      text: '',
      image: {
        alt: '',
        file: {}
      }
    }
  },
  location: {}
}

const MdiList = ({ data, ...props }) => {
  const { iconItems, topText } = defaultsDeep(data, defaultProps.data)

  const renderResource = (mdi, index) => (
    <MdiCard key={uniqueId(`mdi-list__item-${index}`)} data={mdi} location={props.location} />
  )

  return (
    <section className="mdi-list">
      <div className="mdi-list__inner">
        {topText.title && (
          <div className="mdi-list__top-text">
            <Text data={topText} />
          </div>
        )}
        {iconItems && iconItems.length && (
          <Grid
            data={{
              gridItems: iconItems.map(renderResource),
              itemsPerColumn: 3,
              needsContainer: false,
            }}
            className="mdi-list__grid"
          />
        )}
      </div>
    </section>
  )
}

// Export Props
MdiList.defaultProps = defaultProps
MdiList.propTypes = propTypes
// Export Component
export default MdiList
