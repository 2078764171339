import React, { useContext, useMemo } from 'react'
import { defaultsDeep } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useI18n } from '../../../lib/hooks'
import { useSpacerList, useSpacerTabs } from '../../../lib/static'
import SpacerChoiceContextProvider, { SpacerChoiceContext } from './SpacerChoiceContext'
// Components
import { SpacerList, SpacerTabs } from '../../containers'
import { Spacer } from '../../cards'
import { SpacerForm } from '../../forms'
// Assets
import './_SpacerChoice.scss'

const propTypes = {
  data: PropTypes.shape({
    homePage: PropTypes.bool,
    anchorText: PropTypes.string,
    anchorTo: PropTypes.string,
    currentPath: PropTypes.string,
  }),
  location: PropTypes.shape({})
}

const defaultProps = {
  data: {
    homePage: false,
    anchorText: '',
    anchorTo: '',
    currentPath: '',
  },
  location: {}
}

const SpacerChoiceRendered = ({ ...props }) => {
  const { navigate } = useI18n()
  const { homePage, anchorText, anchorTo, currentPath } = defaultsDeep(props, defaultProps)
  const spacers = useSpacerList()
  const spacerTabs = useSpacerTabs()
  const { currentSpacer, setCurrentSpacer } = useContext(SpacerChoiceContext)
  const triggerChoiceSpacer = (id = '5') => {
    if (homePage === false) {
      navigate('/', {
        state: {
          activeSpacer: id,
          spacerFormOptions: {
            firstQuestion: '',
            secondQuestion: '',
            thirdQuestion: ''
          }
        }
      })
    } else {
      setCurrentSpacer(id)
    }
  }

  const activeSpacer = spacers.filter((item) => item.id === currentSpacer)[0]
  const additionalSpacerOptions = spacers.filter((item) => {
    if (!activeSpacer.additionalSpacerOptions.length) {
      return false
    }
    return activeSpacer.additionalSpacerOptions.includes(parseInt(item.id))
  })

  const list = useMemo(() => {
    return <SpacerList spacers={spacers} choiceSpacer={triggerChoiceSpacer} {...props} />
  }, [currentSpacer])

  const tabsData = useMemo(() => {
    const { tabsData: result = {} } = spacerTabs.find((spacer) => spacer.id === parseInt(currentSpacer))
    return result
  }, [currentSpacer])

  return (
    <div className="spacer-choice">
      <SpacerForm {...props } />
      <div className="spacer-choice__inner">
        {list}
      </div>
      <div className="spacer-choice__main-spacer">
        {(anchorText && anchorTo) && (
          <div className="spacer-choice__contact-link-wrapper">
            <AnchorLink
              className="spacer-choice__contact-link"
              to={`${currentPath}#${anchorTo}`}
              title={anchorText}
              stripHash
            >
              {anchorText}
            </AnchorLink>
          </div>
        )}
        {homePage && (
          <Spacer data={{ ...activeSpacer, additionalSpacerOptions }} choiceSpacer={triggerChoiceSpacer} />
        )}
      </div>
      {homePage && tabsData.length && (
        <div className="spacer-choice__tabs">
          <SpacerTabs data={{ tabs: tabsData }} />
        </div>
      )}
    </div>
  )
}

// Export Props
SpacerChoiceRendered.defaultProps = defaultProps
SpacerChoiceRendered.propTypes = propTypes

// Export Component
export default function SpacerChoice({ data, ...props }) {
  const { location: { pathname: currentPath } } = defaultsDeep(props)
  const { homePage, anchorText, anchorTo } = defaultsDeep(data, defaultProps.data)
  return (
    <SpacerChoiceContextProvider location={props.location}>
      <SpacerChoiceRendered homePage={homePage} anchorText={anchorText} anchorTo={anchorTo} currentPath={currentPath} />
    </SpacerChoiceContextProvider>
  )
}

SpacerChoice.defaultProps = defaultProps
SpacerChoice.propTypes = propTypes