import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { defaultsDeep, get, uniqueId } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
// Components
import { SiteLink } from '../../nav'
// Assets.
import './_Footer.scss'
import { useI18n } from '../../../lib/hooks'

const today = new Date()

const FooterRendered = ({
  data: {
    layout: { content: footer },
  }, ...props
}) => {
  const { language, defaultLanguage } = useI18n()
  // Get the footer data for the current or default language.
  const footerData = footer.filter((item) => item.language === language)[0] || footer.filter((item) => item.language === defaultLanguage)[0]
  const { top, main, bottom } = defaultsDeep(footerData, props)

  const renderSubmenu = (item) => (
    <SiteLink
			key={uniqueId(`footer__top-link-item-${item.key}`)}
			className="footer__top-link-item"
			aria-label={item.label}
			href={item.link}
		>
      {item.label}
    </SiteLink>
  )

  const renderTopFooter = top.map((item, index) => {
    return (
      <div
				key={uniqueId(`footer__top-info-item-${index}_`)}
				className="footer__top-info-item"
			>
        {item.label && (<div className="footer__top-info-label">{item.label}</div>)}
        {item.links && (<div className="footer__top-info-links">{item.links.map(renderSubmenu)}</div>)}
      </div>
    )
  })

  const renderMainFooter = main.map((item, index) => {
    return (
      <div
				key={uniqueId(`footer__main-info-item-${index}_`)}
				className="footer__main-info-item"
			>
        <div className="footer__main-info-label">{item.label}</div>
        <div
					className="footer__main-info-content"
					dangerouslySetInnerHTML={{ __html: item.content }}
				/>
      </div>
    )
  })

  const bottomLinks = bottom.links.map((item, index) => {
    return (
      <SiteLink
				key={uniqueId(`footer__bottom-link-item-${index}_`)}
				className="footer__bottom-link-item"
				aria-label={item.label}
				href={item.link}
			>
        {item.label}
      </SiteLink>
    )
  })

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__top">{renderTopFooter}</div>
        <div className="footer__main">{renderMainFooter}</div>
        <div className="footer__bottom">
          <div
						className="footer__copyright"
						dangerouslySetInnerHTML={{ __html: bottom.copyright.split('XXXX').join(today.getFullYear()) }}
					/>
          <div
						className="footer__bottom-links">
            {bottomLinks}
          </div>
        </div>
      </div>
    </footer>
  )
}

FooterRendered.propTypes = {
  data: PropTypes.shape({
    layout: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired
  }).isRequired
}

export default function Footer(props) {
  return (
    <StaticQuery
			query={graphql`
        query FooterLayout {
          layout: layoutJson {
            content: footer {
            	language
            	top {
            		label
            		links {
            		  label
                	link
            		}
            	}
            	main {
            		label
            		content
            	}
            	bottom {
           			copyright
              	links {
              		label
                	link
              	}
            	}
            }
          }
        }
      `}
			render={(data) => <FooterRendered data={data} {...props} />}
		/>
  )
}