import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, values } from 'lodash'
// Assets
import './_VideoCard.scss'

const THEME = {
  default: 'default',
  grey: 'grey'
}

const propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.oneOf(values(THEME)),
    title: PropTypes.string,
    video: PropTypes.string,
    caption: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    theme: THEME.default,
    title: '',
    video: '',
    caption: ''
  }
}

const VideoCard = ({ data, ...props }) => {
  const { title, video, theme, caption } = defaultsDeep(data, defaultProps.data)

  return (
    <div
      className="video-card"
      data-theme={theme}
    >
      {title && (
      <h2 className="video-card__title">{title}</h2>
      )}
      <div className="video-card__content">
        <div
          className="video-card__video"
          dangerouslySetInnerHTML={{ __html: video }}
        />
        {caption && (
          <div className="video-card__caption">{caption}</div>
        )}
      </div>
    </div>
  )
}

// Export Props
VideoCard.defaultProps = defaultProps
VideoCard.propTypes = propTypes
// Export Component
export default VideoCard
