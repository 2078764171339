import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_Webinar.scss'
import { Button } from '../../nav'


const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
}

const defaultProps = {
  data: {
    title: '',
    subtitle: ''
  },
}

const Webinar = ({ data, ...props }) => {
  const { title, subtitle, text, link, image } = defaultsDeep(data, defaultProps.data)

  return (
    <article className="webinar">
      <div className="webinar__outer">
        <div className="webinar__subtitle">{subtitle}</div>
        <h2 className="webinar__title">{title}</h2>
        <div className="webinar__text">{text}</div>
        {link && (
          <Button
            className="webinar__link"
            link={link}
            theme={Button.THEME.blue}
          />
        )}
      </div>
      <div className="webinar__image">
        {image && (
          <GatsbyImage alt={image.alt} image={getImage(image.file)} />
        )}
      </div>
    </article>
  )
}

// Export Props
Webinar.defaultProps = defaultProps
Webinar.propTypes = propTypes
// Export Component
export default Webinar
