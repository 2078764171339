import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { Teaser } from '../../cards'
import Grid from '../Grid/Grid'
// Assets
import './_TeasersList.scss'

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    teaserItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string
      })
    }))
  }),
}

const defaultProps = {
  data: {
    title: '',
    teaserItems: {
      title: '',
      text: '',
      link: {
        title: '',
        uri: ''
      }
    }
  },
}

const TeasersList = ({ data, ...props }) => {
  const { title, teaserItems } = defaultsDeep(data, defaultProps.data)

  const renderTeaser = (teaser, index) => (
    <Teaser key={uniqueId(`teasers-list__item-${index}`)} data={teaser} />
  )

  return (
    <section className="teasers-list">
      <div className="teasers-list__inner">
        <h2 className="teasers-list__title">{title}</h2>
        {teaserItems && teaserItems.length && (
          <Grid
            data={{
              gridItems: teaserItems.map(renderTeaser),
              itemsPerColumn: 2,
              needsContainer: false,
            }}
          />
        )}
      </div>
    </section>
  )
}

// Export Props
TeasersList.defaultProps = defaultProps
TeasersList.propTypes = propTypes
// Export Component
export default TeasersList
