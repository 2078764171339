import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep } from 'lodash'
// Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Text } from '../../cards'
import { Button } from '../../nav'

// Assets
import './_ImageWithLink.scss'

const propTypes = {
  data: PropTypes.shape({
    topText: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string
    }),
    image: PropTypes.shape({
      alt: PropTypes.string,
      file: PropTypes.shape({})
    }),
    link: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string
    })
  })
}

const defaultProps = {
  data: {
    topText: {
      title: '',
      text: ''
    },
    image: {
      alt: '',
      file: {}
    },
    link: {
      title: '',
      uri: ''
    }
  }
}

const ImageWithLink = ({ data, ...props }) => {
  const { topText, image, link } = defaultsDeep(data, defaultProps.data)

  return (
    <section className="image-with-link">
      <div className="image-with-link__inner">
        {topText.title && (
          <div className="image-with-link__top-text">
            <Text data={topText} />
          </div>
        )}
        <div className="image-with-link__image">
          {image && (
            <GatsbyImage alt={image.alt} image={getImage(image.file)}/>
          )}
        </div>
        {link && (
          <div className="image-with-link__link">
            <Button
              link={link}
              theme={Button.THEME.blue}
            />
          </div>
        )}
      </div>
    </section>
  )
}

// Export Props
ImageWithLink.defaultProps = defaultProps
ImageWithLink.propTypes = propTypes
// Export Component
export default ImageWithLink