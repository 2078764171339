import React from 'react'
// Data
import Tabs from 'react-responsive-tabs'
// Assets
import './_Accordion.scss'
import { useSpacerAccordionFaq } from '../../../lib/static'

const Accordion = () => {
  const accordionItems = useSpacerAccordionFaq()

  const renderContent = (text) => {
    return (<div
      dangerouslySetInnerHTML={{ __html: text }}
    />)
  }

  const accordion = accordionItems.map((tab, index) => ({
    title: tab.label,
    getContent: () => renderContent(tab.text),
    /* Optional parameters */
    key: index,
    tabClassName: 'accordion__label',
    panelClassName: 'accordion__content',
  }))

  return (
    <div className="accordion">
      <div className="accordion__inner" role="tablist" aria-label="Accordion Items">
        <Tabs
          items={accordion}
          transformWidth={9999999}
          tabsWrapperClass="accordion__labels"
          showMore={false}
          selectedTabKey={null}
        />
      </div>
    </div>
  )
}

// Export Component
export default Accordion
