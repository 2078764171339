import React from 'react'
import * as PropTypes from 'prop-types'
import { get, defaultsDeep, uniqueId } from 'lodash'
// Assets
import './_Grid.scss'

const propTypes = {
  data: PropTypes.shape({
    needsContainer: PropTypes.bool,
    itemsPerColumn: PropTypes.number,
    gridItems: PropTypes.arrayOf(PropTypes.any)
  })
}

const defaultProps = {
  data: {
    needsContainer: false,
    itemsPerColumn: 2,
    gridItems: []
  }
}

const Grid = ({ data }) => {
  const { itemsPerColumn, gridItems, needsContainer } = defaultsDeep(
    data,
    defaultProps.data
  )

  const gridItem = (item, index) => {
    const type = get(item, 'key', '').split('-')[0]
    return (
      <div key={uniqueId(`grid-item-${index}`)} className="grid__item" data-comp={type}>
        {item}
      </div>
    )
  }

  return (
    <div
      className="grid"
      data-items-per-column={itemsPerColumn}
      data-container={needsContainer}
    >
      {gridItems.length > 0 && (
        <div className="grid__item-container">{gridItems.map(gridItem)}</div>
      )}
    </div>
  )
}

// Export Props
Grid.defaultProps = defaultProps
Grid.propTypes = propTypes
// Export Component
export default Grid
