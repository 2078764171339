import React from 'react'
import * as PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// Assets
import './_SideEffectCard.scss'

const propTypes = {
  data: PropTypes.shape({
    alt: PropTypes.string,
    file: PropTypes.shape({})
  })
}

const defaultProps = {
  data: {
    alt: '',
    file: {}
  }
}

const SideEffectCard = ({ data }) => {
  return (
    <div className="side-effect-card">
      <div className="side-effect-card__image">
        <GatsbyImage alt={data.alt} image={getImage(data.file)} />
      </div>
    </div>
  )
}

// Export Props
SideEffectCard.defaultProps = defaultProps
SideEffectCard.propTypes = propTypes
// Export Component
export default SideEffectCard
