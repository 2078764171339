import { graphql, StaticQuery } from 'gatsby'
import { defaultsDeep, get, uniqueId } from 'lodash'
import React, { useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
// Data.
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useI18n } from '../../../lib/hooks'
import { getCookie, getWindow } from '../../../lib'
// Components.
import { SiteLink } from '../../nav'
import ConfirmModal from '../ConfirmModal/ConfirmModal'
// Assets.
import './_HeaderMenu.scss'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleMenu: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

const defaultProps = {
  toggleMenu: null,
  location: {
    pathname: ''
  }
}

const HeaderMenuRendered = ({ data, ...props }) => {
  const win = getWindow()
  const { location: { pathname: currentPath } } = defaultsDeep(props)
  const { language, defaultLanguage, t, navigate } = useI18n()
  // Get the header data for the current or default language.
  const headerMenuData = data.filter((item) => item.language === language)[0] || data.filter((item) => item.language === defaultLanguage)[0]
  const { links } = defaultsDeep(headerMenuData, props)
  const cookieHCP = getCookie('ViewHCP')
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handleOpenConfirmModal = (e) => {
    e.preventDefault()
    setOpenConfirmModal(true)
  }

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false)
  }

  const handleNavigateToMenuLink = (link) => {
    setOpenConfirmModal(false)
    props.toggleMenu()
    navigate(link)
    // Save cookie to not show each time the confirmation modal.
    if (win?.document) {
      win.document.cookie = 'ViewHCP=yes'
    }
  }

  const renderConfirmLink = (item, index) => {
    return (
      <>
        <SiteLink
          className="header-menu__link"
          href={item.link}
          onClick={(e) => handleOpenConfirmModal(e)}
          onKeyPress={(e) => handleOpenConfirmModal(e)}
        >
          {item.label}
        </SiteLink>
        <ConfirmModal
          open={openConfirmModal}
          handleClose={handleCloseConfirmModal}
          handleCancel={handleCloseConfirmModal}
          handleAgree={() => handleNavigateToMenuLink(item.link)}
        />
      </>
    )
  }

  const renderContactLink = useMemo(() => {
    return (
      <li
        key={uniqueId('header-menu__item-contact')}
        className="header-menu__item"
      >
        <AnchorLink
          className="header-menu__link"
          to={`${currentPath}#contact`}
          title={t('contact.menuLabel')}
          onAnchorLinkClick={() => props.toggleMenu(true)}
          stripHash
        >
          {t('contact.menuLabel')}
        </AnchorLink>
      </li>
    )
  }, [currentPath])

  return (
    <ul className="header-menu">
      {links.map((item, index) => (
        (item.confirm && cookieHCP !== 'yes') ? (
          <li
            key={uniqueId(`header-menu__item-${index}`)}
            className="header-menu__item"
          >
            {renderConfirmLink(item, index)}
          </li>
        ) : (
          <li
            key={uniqueId(`header-menu__item-${index}`)}
            className="header-menu__item"
          >
            <SiteLink
              className="header-menu__link"
              href={item.link}
              onClick={props.toggleMenu}
              onKeyPress={props.toggleMenu}
            >
              {item.label}
            </SiteLink>
          </li>
        )
      ))}
      {renderContactLink}
    </ul>
  )
}

// Export Props
HeaderMenuRendered.defaultProps = defaultProps
HeaderMenuRendered.propTypes = propTypes

export default function HeaderMenu(props) {
  return (
    <StaticQuery
			query={graphql`
        query HeaderMenuLayout {
          layout: layoutJson {
            content: header {
              headerMenu {
                language
                links {
                	label
                	link
                  confirm
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerMenuData = get(data, 'layout.content.headerMenu', [])
        return <HeaderMenuRendered data={headerMenuData} {...props} />
      }}
		/>
  )
}