import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Assets
import './_InfoData.scss'
import { ReactComponent as PeopleImage } from '../../../assets/images/components/info-data/people.svg'

const propTypes = {
  data: PropTypes.shape({
    topData: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      }))
    }),
    mainData: PropTypes.shape({
      text: PropTypes.string
    }),
    bottomData: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        caption: PropTypes.string,
        file: PropTypes.shape({})
      }),
      textItems: PropTypes.shape({
        label: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
          text: PropTypes.string
        }))
      })
    }),
  }),
}

const defaultProps = {
  data: {
    topData: {
      title: '',
      text: '',
      images: {
        alt: '',
        file: {}
      }
    },
    mainData: {
      text: ''
    },
    bottomData: {
      title: '',
      text: '',
      image: {
        alt: '',
        caption: '',
        file: {}
      },
      textItems: {
        label: '',
        items: {
          text: ''
        }
      }
    }
  },
}

const InfoData = ({ data, ...props }) => {
  const { topData, mainData, bottomData } = defaultsDeep(data, defaultProps.data)

  const renderImage = (item, index = 0) => (
    <div
      key={uniqueId(`info-data__image-item-${index}`)}
      className="info-data__image"
    >
      <GatsbyImage
        alt={item.alt}
        image={getImage(item.file)}
      />
      {item.caption && (
        <div
          className="info-data__image-caption"
          dangerouslySetInnerHTML={{ __html: item.caption }}
        />
      )}
    </div>
  )

  const renderTopData = () => {
    const { title, text, images } = topData
    return (
      <section className="info-data__top">
        <div className="info-data__content">
          <h1 className="info-data__title">{title}</h1>
          <div
            className="info-data__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
        {images && (
          <div className="info-data__images">
            {images.map(renderImage)}
          </div>)}
      </section>
    )
  }

  const renderMainData = () => {
    const { text } = mainData
    return (
      <section className="info-data__main">
        <div className="info-data__image">
          <PeopleImage />
        </div>
        <div
          className="info-data__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </section>
    )
  }

  const renderBottomData = () => {
    const { title, text, image, textItems } = bottomData
    return (
      <section className="info-data__bottom">
        <div className="info-data__content">
          <h2 className="info-data__title">{title}</h2>
          <div
            className="info-data__text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          {textItems.items && (
            <div className="info-data__text-items-wrapper">
              <div className="info-data__label">{textItems.label}</div>
              <div className="info-data__text-items">
                {textItems.items.map((item, index)=> (
                  <div
                    key={uniqueId(`info-data__text-item-${index}`)}
                    className="info-data__text-item"
                  >
                    {item.text}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="info-data__image">
          {renderImage(image)}
        </div>
      </section>
    )
  }

  return (
    <div className="info-data">
      <div className="info-data__inner">
        {renderTopData()}
        {renderMainData()}
        {renderBottomData()}
      </div>
    </div>
  )
}

// Export Props
InfoData.defaultProps = defaultProps
InfoData.propTypes = propTypes
// Export Component
export default InfoData
