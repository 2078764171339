import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId } from 'lodash'
// Components
import { Webinar } from '../../cards'
// Assets
import './_WebinarsList.scss'

const propTypes = {
  data: PropTypes.shape({
    webinarItems: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        file: PropTypes.shape({})
      })
    })),
  }),
}

const defaultProps = {
  data: {
    webinarItems: {
      title: '',
      subtitle: '',
      image: {
        alt: '',
        file: {}
      }
    }
  },
}

const WebinarsList = ({ data, ...props }) => {
  const { webinarItems } = defaultsDeep(data, defaultProps.data)

  const renderWebinar = (webinar, index) => (
    <Webinar key={uniqueId(`webinars-list__item-${index}`)} data={webinar} />
  )

  return (
    <section className="webinars-list">
      <div className="webinars-list__inner">
        {webinarItems && webinarItems.length && (
          <div className="webinars-list__items">
            {webinarItems.map(renderWebinar)}
          </div>
        )}
      </div>
    </section>
  )
}

// Export Props
WebinarsList.defaultProps = defaultProps
WebinarsList.propTypes = propTypes
// Export Component
export default WebinarsList
