import React from 'react'
import * as PropTypes from 'prop-types'
import { keys, difference } from 'lodash'
// Data
import { Link as LocalizedLink } from 'gatsby-plugin-react-i18next'
import useI18n from '../../../lib/hooks/useI18n'
// Assets
import './_SiteLink.scss'

const SiteLink = ({ children, href = '', ...props }) => {
  const { language } = useI18n()
  let link
  const linkProps = [
    'activeClassName',
    'activeStyle',
    'onClick',
    'partiallyActive',
    'replace',
    'to',
    'children',
    'target',
  ]
  let uri = href.replace('internal:', '')
  const isInternal = uri.indexOf('/') === 0
  const isExternal = uri.indexOf('http') === 0
  const isAssetLink = /\/assets\//.test(uri)

  if (!uri) {
    link = <span {...props}>{children}</span>
  } else if (
    // If this is an internal link
    isInternal
    // If we have keys which show we're not an A tag
    && difference(keys(props), linkProps).length
    // And we're not an Asset link...
    && !isAssetLink
  ) {
    // Render the internal localized link
    link = (
      <LocalizedLink {...props} to={uri} activeClassName="active">
        {children}
      </LocalizedLink>
    )
  } else {
    if (isExternal || isAssetLink) {
      // Send off-site links to new tabs by default
      props.target = '_blank'
      props.rel = 'noopener noreferrer'
    } else if (isInternal && !/^\/(en|fr).*$/.test(uri)) {
      // If language is missing, add it.
      uri = `/${language}${uri}`
    }

    link = (
      <a {...props} href={uri}>
        {children}
      </a>
    )
  }

  return link
}

SiteLink.propTypes = {
  href: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default SiteLink
