import React from 'react'
import * as PropTypes from 'prop-types'
import { defaultsDeep, uniqueId, values } from 'lodash'
// Components
import Grid from '../Grid/Grid'
// Assets
import './_CardsList.scss'
import { CtaCard, ResourceCard, StepCard, VideoCard } from '../../cards'

const THEME = {
  default: 'default',
}

const propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.oneOf(values(THEME)),
    topList: PropTypes.shape({
      columnCount: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    bottomList: PropTypes.shape({
      columnCount: PropTypes.number,
      items: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }),
}

const defaultProps = {
  data: {
    theme: THEME.default,
    topList: {
      columnCount: null,
      items: []
    },
    bottomList: {
      columnCount: null,
      items: []
    }
  },
}

const CardsList = ({ data, ...props }) => {
  const { theme, topList, bottomList } = defaultsDeep(data, defaultProps.data)

  const renderCard = (card, index) => {
    const cardData = { ...card }
    const { type } = card
    switch (type) {
      case 'step':
        return (
          <StepCard
            key={uniqueId(`cards-list__item-${index}`)}
            data={cardData}
          />
        )
      case 'video':
        return (
          <VideoCard
            key={uniqueId(`cards-list__item-${index}`)}
            data={cardData}
          />
        )
      case 'resource':
        return (
          <ResourceCard
            key={uniqueId(`cards-list__item-${index}`)}
            data={cardData}
          />
        )
      case 'cta':
        return (
          <CtaCard
            key={uniqueId(`cards-list__item-${index}`)}
            data={cardData}
          />
        )
      default:
        return false
    }
  }

  const renderList = (listData) => {
    const { items, columnCount } = listData
    return (<Grid
      data={{
        gridItems: items.map(renderCard),
        itemsPerColumn: columnCount,
        needsContainer: false,
      }}
    />)
  }

  return (
    <div
      className="cards-list"
      data-theme={theme}
    >
      {topList && topList.items.length > 0 && (
        <div className="cards-list__top">
          {renderList(topList)}
        </div>
      )}
      {bottomList && bottomList.items.length > 0 && (
        <div className="cards-list__bottom">
          {renderList(bottomList)}
        </div>
      )}
    </div>
  )
}

// Export Props
CardsList.defaultProps = defaultProps
CardsList.propTypes = propTypes
// Export Enums
CardsList.THEME = THEME
// Export Component
export default CardsList
