import React, { useContext } from 'react'
import { defaultsDeep } from 'lodash'
import * as PropTypes from 'prop-types'
// Data
import { useI18n, useValidators } from '../../../lib/hooks'
import { SpacerChoiceContext } from '../../layout/SpacerChoice/SpacerChoiceContext'
// Components
import { useForm } from '../Form'
// Assets
import './_SpacerForm.scss'

const propTypes = {
  homePage: PropTypes.bool
}

const defaultProps = {
  homePage: false
}

const SpacerForm = ({ ...props }) => {
  const { navigate, t, language } = useI18n()
  const { homePage } = defaultsDeep(props, defaultProps)
  const { setCurrentSpacer, spacerOptions } = useContext(SpacerChoiceContext)
  const validators = useValidators()
  const { firstQuestionDefaultValue, secondQuestionDefaultValue, thirdQuestionDefaultValue } = spacerOptions

  const firstQuestionOptions = [
    {
      label: t('spacerChoice.form.firstSelect.options.first'),
      value: 'first_option',
    },
    {
      label: t('spacerChoice.form.firstSelect.options.second'),
      value: 'second_option',
    },
    {
      label: t('spacerChoice.form.firstSelect.options.third'),
      value: 'third_option',
    },
    {
      label: t('spacerChoice.form.firstSelect.options.fourth'),
      value: 'fourth_option',
    }
  ]
  const secondQuestionOptions = [
    {
      label: t('spacerChoice.form.secondSelect.options.first'),
      value: 'first_option',
    },
    {
      label: t('spacerChoice.form.secondSelect.options.second'),
      value: 'second_option',
    }
  ]
  const thirdQuestionOptions = [
    {
      label: t('spacerChoice.form.thirdSelect.options.first'),
      value: 'first_option',
    },
    {
      label: t('spacerChoice.form.thirdSelect.options.second'),
      value: 'second_option',
    }
  ]

  const Form = useForm({
    className: 'spacer-form__form-wrapper',
    fields: {
      firstQuestion: {
        caption: t('spacerChoice.form.firstSelect.caption'),
        label: null,
        type: 'select',
        options: firstQuestionOptions,
        validator: validators.yupString.required(t('forms.errors.required')),
        defaultValue: homePage ? firstQuestionDefaultValue : ''
      },
      secondQuestion: {
        caption: t('spacerChoice.form.secondSelect.caption'),
        label: null,
        type: 'select',
        options: secondQuestionOptions,
        validator: validators.yupString.required(t('forms.errors.required')),
        defaultValue: homePage ? secondQuestionDefaultValue : ''
      },
      thirdQuestion: {
        caption: t('spacerChoice.form.thirdSelect.caption'),
        label: null,
        type: 'select',
        options: thirdQuestionOptions,
        validator: validators.yupString.required(t('forms.errors.required')),
        defaultValue: homePage ? thirdQuestionDefaultValue : ''
      }
    },
    actions: [
      {
        label: t('spacerChoice.form.submit.label'),
      },
    ],
    onSubmit: (values, { setSubmitting }, formError) => {
      const { firstQuestion, secondQuestion, thirdQuestion } = values
      let spacer = '5'
      switch (firstQuestion) {
        case 'first_option':
          spacer = '1'
          break
        case 'second_option':
          spacer = '2'
          break
        case 'third_option':
          switch (secondQuestion) {
            case 'first_option':
              switch (thirdQuestion) {
                case 'first_option':
                  spacer = '3'
                  break
                case 'second_option':
                  spacer = '4'
                  break
              }
              break
            case 'second_option':
              spacer = '6'
              break
          }
          break
        case 'fourth_option':
          switch (secondQuestion) {
            case 'first_option':
              switch (thirdQuestion) {
                case 'first_option':
                  spacer = '5'
                  break
                case 'second_option':
                  spacer = '4'
                  break
              }
              break
            case 'second_option':
              spacer = '7'
              break
          }
          break
      }

      setCurrentSpacer(spacer)
      if (homePage === false) {
        navigate('/', {
          state: {
            activeSpacer: spacer,
            spacerFormOptions: {
              firstQuestion,
              secondQuestion,
              thirdQuestion
            }
          }
        })
      }
      setSubmitting(false)
    },
  })

  return (
    <div className="spacer-form">
      <div className="spacer-form__inner">
        <h2 className="spacer-form__title">{t('spacerChoice.title')}</h2>
        {language !== 'uk' && (
          Form.render
        )}
      </div>
    </div>
  )
}

// Export Props
SpacerForm.defaultProps = defaultProps
SpacerForm.propTypes = propTypes
// Export Component
export default SpacerForm
