import React from 'react'
import * as PropTypes from 'prop-types'
// Data
import { isReactComponent } from '../../../lib'
import { useRenderedComponents } from '../../../schema/render'

const propTypes = {
  node: PropTypes.shape({}),
  location: PropTypes.shape({})
}

const defaultProps = {
  node: {},
  location: {}
}

const Landing = ({ node, ...props }) => {
  const content = useRenderedComponents(node, props.location)
  return (
    <div className="landing-page">
      {content.map((comp) => (isReactComponent(comp) ? comp : null))}
    </div>
  )
}

// Export Props
Landing.defaultProps = defaultProps
Landing.propTypes = propTypes
// Export Component
export default Landing