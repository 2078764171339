/* eslint-disable no-case-declarations, indent */
import React, { useEffect, useState } from 'react'
import { uniqueId, isObject } from 'lodash'
import { COMPONENT_TYPES } from '../types'
import { Raw } from '../../components/meta'
import { InfoData, Text, TextWithIcon, TextWithVideo } from '../../components/cards'
import { Hero, VideoHero } from '../../components/banners'
import {
  Accordion,
  CardsList,
  ResourcesList,
  DeliveryList,
  OvercomeList,
  ImageWithLink,
  MdiList,
  SideEffectsList,
  TeasersList,
  TestimonialsList,
  TextWithListColumns,
  WebinarsList
} from '../../components/containers'
import { FAQ, PreFooter, SpacerChoice } from '../../components/layout'

const renderComponent = (paragraphs = [], location = {}) => {
  const toRender = !Array.isArray(paragraphs) && isObject(paragraphs) ? [paragraphs] : paragraphs
  const formatted = []

  if (!toRender) {
    return formatted
  }

  toRender.forEach((component = {}, index) => {
    // Grab the paragraphs type, needed for the key and type checking.
    const { componentType: componentDrupalType } = component
    const { paragraphType: { drupalId: componentStaticType = '' } = {} } = component
    const componentType = componentStaticType || componentDrupalType
    // A mutable reference to the final data to be passed into the rendered
    // component.
    const data = { ...component }
    // A dedicated key for this page element.
    const key = uniqueId(`${componentType}-${index}`)
    // The possible rendered element, or null if not matching types are found.
    let comp = null

    // Determine the paragraph to render.
    switch (componentType) {
      case COMPONENT_TYPES.textWithVideo:
        comp = <TextWithVideo key={key} data={data} />
        break
      case COMPONENT_TYPES.hero:
        comp = <Hero key={key} data={data} />
        break
      case COMPONENT_TYPES.videoHero:
        comp = <VideoHero key={key} data={data} />
        break
      case COMPONENT_TYPES.text:
        comp = <Text key={key} data={data} />
        break
      case COMPONENT_TYPES.spacerChoice:
        comp = <SpacerChoice key={key} data={data} location={location} />
        break
      case COMPONENT_TYPES.accordion:
        comp = <Accordion key={key} />
        break
      case COMPONENT_TYPES.cardsList:
        comp = <CardsList key={key} data={data} />
        break
      case COMPONENT_TYPES.faq:
        comp = <FAQ key={key} data={data} />
        break
      case COMPONENT_TYPES.textWithIcon:
        comp = <TextWithIcon key={key} data={data} />
        break
      case COMPONENT_TYPES.infoData:
        comp = <InfoData key={key} data={data} />
        break
      case COMPONENT_TYPES.teasersList:
        comp = <TeasersList key={key} data={data} />
        break
      case COMPONENT_TYPES.testimonialsList:
        comp = <TestimonialsList key={key} data={data} />
        break
      case COMPONENT_TYPES.webinarsList:
        comp = <WebinarsList key={key} data={data} />
        break
      case COMPONENT_TYPES.resourcesList:
        comp = <ResourcesList key={key} data={data} />
        break
      case COMPONENT_TYPES.deliveryList:
        comp = <DeliveryList key={key} data={data} />
        break
      case COMPONENT_TYPES.mdiList:
        comp = <MdiList key={key} data={data} location={location} />
        break
      case COMPONENT_TYPES.overcomeList:
        comp = <OvercomeList key={key} data={data} />
        break
      case COMPONENT_TYPES.sideEffectsList:
        comp = <SideEffectsList key={key} data={data} />
        break
      case COMPONENT_TYPES.textWithListColumns:
        comp = <TextWithListColumns key={key} data={data} />
        break
      case COMPONENT_TYPES.preFooter:
        comp = <PreFooter key={key} data={data} />
        break
      case COMPONENT_TYPES.imageWithLink:
        comp = <ImageWithLink key={key} data={data} />
        break
    }

    if (comp) {
      formatted.push(comp)
    } else {
      formatted.push(<Raw key={key} type={componentType} comp={component} />)
    }
  })
  return formatted
}

export const useRenderedComponents = (node = null, location = {}) => {
  const [content, setContent] = useState(renderComponent(node.content, location) || [])
  useEffect(() => {
    if (node.content) {
      setContent(renderComponent(node.content, location) || [])
    }
  }, [node])

  return content
}

export default renderComponent
